import { Badge } from 'antd';
import React, { useEffect, useState ,useRef } from 'react'
import { useLocation } from 'react-router';
import MultiSelectEntity from '../RefineSearch/TgMultiSelectEntity'
import FilterWrapper from './FilterWrapper';
import { filterAcquirers, filterReAcquirers } from "../../Config/config"
import useScrollIntoView from './useScrollIntoView';

function Acquirers({ open, accordionClick, options, setOptions, customPathname, label }) {
    const [companyVal, setCompanyVal] = useState("");
    const [fetchedData, setFetchedData] = useState([]);
    const shouldScroll = open || fetchedData.length > 0;
    const listItemRef = useScrollIntoView(shouldScroll);

    useEffect(() => {
        if (companyVal !== "") {
            fetchData();
        }
    }, []);

    const fetchData = () => {
        fetch(customPathname.includes("re") ? filterReAcquirers : filterAcquirers, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ search_key: companyVal })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data?.results) {
                    const uniqueData = data.results.filter(item => !options.some(option => option.id === item.id));
                    setFetchedData(uniqueData); 
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        const uniqueFetchedData = fetchedData.filter(item => !options.some(option => option.id === item.id));
                setOptions(prevOptions => [...prevOptions.filter(item => item.isChecked === true), ...uniqueFetchedData]);

    }, [fetchedData]);

    const handleIndustryChange = ((e) => {
        const { name, checked, id } = e.target;
        let currentIndustry = [];

        if (name === "allSelect") {
            currentIndustry = options.map((industry) =>
                ({ ...industry, isChecked: checked })
            );
            setOptions(currentIndustry);
        } else {
            currentIndustry = options.map((industry) =>
                industry.id === parseInt(id) ? { ...industry, isChecked: checked } : industry
            );
            setOptions(currentIndustry);
        }
    });



    const selectedIndustryLength = options?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"} ref={listItemRef}>
                <FilterWrapper
                    count={selectedIndustryLength}
                    isSelect={false}
                    label={label}
                    onClick={accordionClick}
                    open={open}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        fetchData={fetchData}
                        handleEntityChange={handleIndustryChange}
                        primaryEntity={options}
                        setCompanyVal={setCompanyVal}
                        city="city"
                    /> : <></>}
                </div>
            </li>
        </div>
    )
}

export default Acquirers;
