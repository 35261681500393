import React, { useState,useContext } from 'react'
import MainMenu from '../../Pages/RE/ReMainMenu'
import Card from '../../Components/RE/ReListCard/AdvisorListCard'
import FilterTag from '../../Components/Tag'
import Table from '../../Components/RETransactionAdvisor/TableComponent'
import RefineSearch from '../../Components/RefineSearchComponent'
import ReLayout from '../../Components/ReLayout'
import MonthPickerComponent from "../../Utils/MonthPickerComponent";
import xls from "../../images/xls/png/Group 47.png";
import FileIcon from "../../images/file.png";
import FileIconBlur from "../../images/fileblur.png";
import line from "../../images/line.png";
import SavedFilterContent from "../../Components/SavedFilter";
import SavedFilterICON from "../../Components/SavedFilter/SavedFilterICON";
import ExportPopup from "../../Components/ExportPopup";
import { reAdvisorListExport } from "../../Config/config";
import Constants from '../../Constants'
import { useLocation } from 'react-router-dom'
import { reTransactionAdvisorOtherMAExportData } from "../../Components/ExportPopup/ExportData"
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import ReInvestorExitRefineSearch from '../../Components/RefineSearch/ReInvestorExitRefineSearch'
import { UserContext } from '../../Contexts/UserContext'
import { TableDataContext } from '../../Contexts/TableDataContext'

/**
 * The REAngelInvestment function is a JavaScript function that renders a component for displaying RE
 * Advisor Other MA transactions and provides filtering and search functionality.
 * @returns a JSX element.
 */
function REAngelInvestment() {

    const {save, setSave,showMonthPicker,refineSearchFilters ,groupId} = useContext(PeFilterContext);
    const [showPopup, setShowPopup] = useState(false);
    const savedList = () => {
        setSave(!save)
    }
    const {isTrialExport,isStudent} = useContext(UserContext)
    const {globalSearchResult} = useContext(TableDataContext);

    const location = useLocation();
    const {pathname} = useLocation()
    /**
     * The function checks if there are any global search filters applied 
     */
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    document.title = "RE Transaction Advisors PE-RE Other M&A - Venture Intelligence"

    return (
        <ReLayout>
            <div className="flex min-h-screen h-screen flex-col">
            { isFromGlobalSearch() ? <></> :<Card />}
                <div className="flex min-h-0 flex-1 overflow-hidden">
                    <main className={`min-w-0 flex-1 md:border-t border-gray-200 lg:flex ${isFromGlobalSearch() ? 'mt-[4.25rem] md:mt-[3rem]':''}`}>
                        <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                            <FilterTag />
                            <div className="pl-3 pr-4 lg:pr-4 md:pr-[0.7rem]">
                                <div className="scrollbar ">
                                    <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">

                                        <MainMenu active={3} menu="t-advisor" primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                            subTypeMasterId={Constants.primaryMasterSubIds.re_transaction_otherma_id}
                                            typeId={Constants.moduleIds.t_advisor_id} />
                                        
                                        <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                                                {!isFromGlobalSearch() && <label htmlFor="" className="text-[#666666] ">
                                                    <div className={showMonthPicker!=0 ?"":'flex'}>
                                                        <MonthPickerComponent />
                                                    </div>
                                                </label>}
                                                <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>   
                                                    {isTrialExport == 'false' || isStudent == 'true' ? <></> :<>
                                                        <button>
                                                            <img
                                                                src={xls}
                                                                className="h-[24px] mt-1 max-w-[21px]"
                                                                alt=""
                                                                onClick={() => setShowPopup(!showPopup)}
                                                            />
                                                            {showPopup && (
                                                                <ExportPopup 
                                                                modules="advisor"
                                                                module="transaction-advisor"
                                                                fileName={Constants.exportNames.re_advisor_ipos}
                                                                url={reAdvisorListExport}
                                                                onClose={() => setShowPopup(false)} 
                                                                advisor_type="T"
                                                                exportOptions={reTransactionAdvisorOtherMAExportData} 
                                                                type="other-manda"  />
                                                            )}
                                                        </button>                                                
                                                    <a>
                                                        <img className="h-[24px] max-w-[2px] mt-1 " src={line} />
                                                    </a>
                                                    </>}
                                                    {groupId?.myList?.length >0 ?<></>:<SavedFilterICON
                                                        save={save}
                                                        FileIcon={FileIcon}
                                                        FileIconBlur={FileIconBlur}
                                                        savedList={savedList} />}
                                                </div>
                                            </div>
                                        
                                    </div>
                                </div>
                                {save ? <SavedFilterContent primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                    subTypeMasterId={Constants.primaryMasterSubIds.re_transaction_otherma_id}
                                    typeId={Constants.moduleIds.t_advisor_id}
                                    show={save} /> : ''}
                            </div>
                            <div className='overflow-y-auto'>
                                <Table menu="re-advisor-otherma" advisor_type="T" sub_type_master_id={Constants.subCategoryIds.re_tadvisor_otherma_id} />
                            </div>
                            {/* <Outlet /> */}
                            {/* Your content */}
                        </section>

                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-[100%] flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch>
                                <ReInvestorExitRefineSearch customPathname={location.pathname} dataPath="other-manda" adviosrType="T"/>
                                </RefineSearch>
                            </div>
                        </aside>
                    </main>
                </div>
            </div>
        </ReLayout>
    )
}

export default REAngelInvestment
