import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Card from "../../../Components/MA/ListCard/Card";
import MALayout from "../../../Components/M&ALayout";
import MainMenu from "../MA_MainMenu";
import Table from "../../../Components/Acquirer/TableComponent";
import FilterTag from "../../../Components/Tag";
import RefineSearchBar from "../../../Components/RemovedArrowRefineSearch";
import Constants from "../../../Constants";
import xls from "../../../images/xls/png/Group 47.png";
import line from "../../../images/line.png";
import moment from "moment";
import FileIcon from "../../../images/file.png";
import FileIconBlur from "../../../images/fileblur.png";
import MonthPickerComponent from "../../../Utils/MonthPickerComponent";
import SavedFilterContent from "../../../Components/SavedFilter";
import { TableDataContext } from "../../../Contexts/TableDataContext";
import { maAcquirerListExportApi } from "../../../Config/config";
import ExportPopup from "../../../Components/ExportPopup";
import { PeFilterContext } from "../../../Contexts/PeFilterContext";
import { maAcquirerProfileExportData } from "../../../Components/ExportPopup/ExportData";
import { UserContext } from "../../../Contexts/UserContext";

/* The above code is a React component called "Acquirer". It is a part of a larger application and is
responsible for rendering a page that displays a table of acquirer data. */
function Acquirer(props) {
  const [showPopup, setShowPopup] = useState();
  const [openTab, setOpenTab] = React.useState(1);
  const [date, setDate] = useState([
    moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
    moment().endOf("month").format("YYYY-MM-DD"),
  ]);
  // const [save, setSave] = useState(false);
  const savedList = () => {
    setSave(!save);
  };
  useEffect(() => {
    setOpenTab(props.active);
  }, [openTab]);
  const { save, setSave, showMonthPicker, refineSearchFilters, groupId } =
    useContext(PeFilterContext);
  const { globalSearchResult } = useContext(TableDataContext);
  const { pathname } = useLocation();
  const { isTrialExport, isStudent } = useContext(UserContext);

  /**
   * The function checks if there are any global search filters applied
   */
  const isFromGlobalSearch = () =>
    refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false;

  return (
    <MALayout menu="ma">
      <div className="flex min-h-screen h-screen flex-col">
        {/* Top nav*/}
        {isFromGlobalSearch() ? <></> : <Card />}
        {/* Bottom section */}

        <div
          className={
            isFromGlobalSearch()
              ? "flex min-h-0 mt-[4rem] flex-1 overflow-hidden"
              : "flex min-h-0 flex-1 overflow-hidden"
          }
        >
          {/* Narrow sidebar*/}
          {/* Main area */}
          <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">
            {/* Primary column */}
            <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
              {/* Your content */}
              <div className="m-4 rounded-t-md bg-white flex items-center justify-between ">
                <FilterTag />
                <div className="pl-3 pr-4 lg:pr-4 md:pr-[0.7rem]">
                    <div className="px-2 md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-end">
                      <div
                        className={
                          "justify-between items-center px-2 py-2 space-x-4 " +
                          (showMonthPicker != 0 ? "md:flex" : "flex")
                        }
                      >
                        {!isFromGlobalSearch() && <label htmlFor="" className="text-[#666666] ">
                          <div className={showMonthPicker != 0 ? "" : "flex"}>
                            <MonthPickerComponent />
                          </div>
                        </label>}

                        <div
                          className={
                            showMonthPicker != 0
                              ? "hidden md:flex space-x-3"
                              : "flex space-x-3"
                          }
                        >
                          {isTrialExport == 'false'|| isStudent == "true" ? (
                            <></>
                          ) : (
                            <>
                              <button>
                                <img
                                  src={xls}
                                  className="h-[24px] mt-1 w-[21px]"
                                  alt=""
                                  onClick={() => setShowPopup(!showPopup)}
                                />
                                {showPopup && (
                                  <ExportPopup
                                    modules="ma-acquirer"
                                    fileName={
                                      Constants.exportNames.ma_acquirers
                                    }
                                    url={maAcquirerListExportApi}
                                    onClose={() => setShowPopup(false)}
                                    exportOptions={maAcquirerProfileExportData}
                                  />
                                )}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {save ? (
                      <SavedFilterContent
                        primaryTypeMasterId={Constants.primaryMasterIds.mANDaId}
                        typeId={Constants.moduleIds.acquirer_id}
                        show={save}
                      />
                    ) : (
                      ""
                    )}
                  </div>
              </div>
              <div className="overflow-y-auto">
                <Table
                  menu="ma"
                  sub_type_master_id={Constants.subCategoryIds.ma_acquirer_id}
                />
              </div>
            </section>
            {/* Secondary column (hidden on smaller screens) */}
            <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
              <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                {/* Your content */}
                <RefineSearchBar />
              </div>
            </aside>
          </main>
        </div>
      </div>
    </MALayout>
  );
}

export default Acquirer;
