import { useContext, useState } from "react";
import { routeNames } from "../../routeSegments";
import { NavLink } from "react-router-dom";
import NewLogo from "../NewLogo";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "antd";
import { HeaderMenuContext } from "../../Contexts/HeaderMenuContext";
import { UserContext } from "../../Contexts/UserContext";
import Constants from "../../Constants";
import ConfirmPopup from "../../Utils/ConfirmPopup";

export const SearchResult = ({ result, SearchResult, key }) => {
  const { request_params, hits, found } = result;
  const collectionName = request_params?.collection_name.replace("_", " ");
  const { setSearchText } = useContext(HeaderMenuContext);
  const { getAllowedModules } = useContext(UserContext);
  const allowedModules = getAllowedModules();
  const [alert, setalert] = useState({ message: "", show: false });

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const asHumanDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  }

  const pevcSubscriber = allowedModules.includes("pe") || allowedModules.includes("vc")
  const cfsSubscriber = allowedModules.includes("cfs");
  const reSubscriber = allowedModules.includes("re");
  const maSubscriber = allowedModules.includes("ma");

  const urlMappings = [
    { accessor: 'pe_company', link: (cmpId) => routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'pe_investment', link: (cmpId, dealId) => routeNames.peCompanyInvestmentDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'pe_exit', link: (cmpId, dealId) => routeNames.peCompanyExitsDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'pe_ipo', link: (cmpId, dealId) => routeNames.peCompanyBackedIPOSDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'angel', link: (cmpId, dealId) => routeNames.peCompanyAngelDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'incubation', link: (cmpId, dealId) => routeNames.peCompanyIncubationDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'pe_investor', link: (cmpId) => routeNames.peInvestorById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'pe_fund', link: (cmpId, dealId) => routeNames.pe_investors_funds_detail_page(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'pe_advisor', link: (cmpId) => routeNames.peAdvisorById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'pe_acquirer', link: (cmpId) => routeNames.maAcquiresById(cmpId), isAllowed: maSubscriber },
    { accessor: 'merger_acquisition', link: (cmpId, dealId) => routeNames.maCompanyDealById(cmpId, dealId), isAllowed: maSubscriber },
    { accessor: 're_company', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber },
    { accessor: 're_investment', link: (cmpId, dealId) => routeNames.reCompanyInvestmentDealById(cmpId, dealId), isAllowed: reSubscriber },
    { accessor: 're_exit', link: (cmpId, dealId) => routeNames.reCompanyExitsDealById(cmpId, dealId), isAllowed: reSubscriber },
    { accessor: 're_ipo', link: (cmpId, dealId) => routeNames.reCompanyBackedIPOSDealById(cmpId, dealId), isAllowed: reSubscriber },
    { accessor: 're_investor', link: (cmpId) => routeNames.reInvestorById(cmpId), isAllowed: reSubscriber },
    { accessor: 're_advisor', link: (cmpId) => routeNames.reAdvisorById(cmpId), isAllowed: reSubscriber },
    { accessor: 'cfs_company', link: (cmpId) => routeNames.cfsCompanyPageById(cmpId), isAllowed: cfsSubscriber },
  ];


  const generateUrl = (collectionName, cmpId, dealId) => {
    const urlMapping = urlMappings.find(mapping => mapping.accessor === collectionName);
    return urlMapping ? urlMapping.link(cmpId, dealId) : '/';
  };

  const fieldNameMapHuman = {
    company__name: "Co name",
    investor__name: "Investor",
    acquirer__name: "Acquirer",
    additional_info: "Addl info",
    advisors_for_buyer__name: "Advisor",
    advisors_for_company__name: "Advisor",
    advisors_for_exit_company__name: "Advisor",
    advisors_for_investor__name: "Advisor",
    advisors_for_ma_acquirer__name: "Advisor",
    advisors_for_ma_company__name: "Advisor",
    advisors_for_seller__name: "Advisor",
    auditor_name: "Auditor",
    board_info__name: "Co Board",
    business_description: "Description",
    c_tag: "Competitor",
    cin: "CIN",
    company_group: "Group Co",
    contact_name: "Contact",
    contact_person: "Contact",
    description: "Description",
    email: "Email",
    exit_investors__name: "Investor",
    full_name: "Co name",
    fund__name: "Fund",
    incubator__name: "Incubator",
    investment_details: "Investment",
    investment_info: "Investment",
    investor__name: "Investor",
    investors__name: "Investor",
    ipo_investors__name: "Investor",
    key_contact: "Contact",
    limited_partners: "LP",
    management_info__name: "Management",
    manager: "Manager",
    more_info: "Deal details",
    name: "Co name",
    old_cin: "CIN",
    previous_name: "Previous name",
    project_name: "Project",
    sector: "Sector",
    selling_investors: "Investor",
    short_name: "Short name",
    subsector: "Sector",
    tags: "Tag",
    website: "Website",
    company__cin: "CIN",
    company__tags: "Tags",
    company__c_tag: "Competitor tag",
    company__sector: "Sector",
    company__website: "Website",
    company__email: "Email",
    company__additional_info: "Addl info",
    company__board_info__name: "Co Board",
    company__management_info__name: "Management",
    company__cin__business_description: "Business description",
    cin__business_description: "Business description"
  }

  const getDescription = (doc) => {
    return doc.business_description !== "None" &&
      doc.description !== "None" &&
      doc.cin__business_description !== "None" &&
      doc.company__cin__business_description !== "None";
  };

  const getFirstNonEmptyField = (doc) => {
    return doc.business_description ||
      doc.description ||
      doc.cin__business_description ||
      doc.company__cin__business_description;
  };

  const onConfirmation = () => {
    setalert({ show: false, message: "" })
  }

  const onBlurColumnClick = () => {
    setalert({ show: true, message: Constants.subscribeProductMsg, isAlertOnly: true, closeBtnName: "OK" })
  }


  const skipDescriptionIfPossible = (highlight, index) => {
    return (
      <span className="whitespace-nowrap overflow-hidden overflow-ellipsis mr-3">
        <span className="bg-gradient-to-br from-amber-100 to-rose-100 px-1 rounded text-black text-xs mr-1">
          {fieldNameMapHuman[highlight.field] || highlight.field}:</span>
        <span
          key={index}
          dangerouslySetInnerHTML={{ __html: highlight.snippet }}
          className="mark-custom text-black font-sans_book_body flex-grow text-[13px] lg:text-[14px] break-words"
        />
        
      </span>
    )
  }

  const checkMarkUptext = (snippet, coName) => {
    return snippet?.toLowerCase()?.replace(/<\/?mark>/g, '') == coName?.toLowerCase()
  }

  const highlightsSectionHandler = (hit) => {
    const skippableNames = ["company__name", "full_name", "name", "fund__name", "c_tag", "company__c_tag", "short_name"]
    const coName = (hit.document.company__name || hit.document.name || hit.document.full_name || hit.document.fund__name)
    const highlights = hit.highlights.filter((h) => (!(skippableNames.includes(h.field) && checkMarkUptext(h.snippet, coName))))
    return highlights.map((h, index) => skipDescriptionIfPossible(h, index))
  }






  return (
    <>
      <div className={`${SearchResult?.length - 1 === key ? "border-b" : ""}`}>
        <div className="border border-slate-200">
          <div className="w-full text-left">
            <div className="border-b border-slate-200 bg-gray-200 text-gray-700 grid grid-cols-1 md:grid-cols-2 justify-between items-center w-full font-semibold px-8 py-1">
              <div className="hidden md:block col-span-1">Entity Name</div>
              <div className="md:col-span-1">Search result highlights</div>
            </div>
            <div>
              {hits?.map((hit, index) => (

                <div key={index} className="py-2 odd:bg-white even:bg-slate-50 align-middle grid grid-cols-1 md:grid-cols-2 justify-between items-center pl-2 md:pl-4">
                  <div className="flex items-start my-2 text-[#c48a09] flex-col">
                    <div className="flex items-center gap-1">
                      <NewLogo id={hit.document.company_id}
                        menu={request_params.collection_name === "cfs_company" ? "cfs" : ["re_company", "re_investment"].includes(request_params.collection_name) ? "re" : "pe"}
                        name={hit.document.company__name || hit.document.name || hit.document.full_name || hit.document.fund__name}
                      />
                      <NavLink
                        className="overflow-hidden overflow-ellipsis"
                        onClick={(e) => {
                          setSearchText("");
                          const urlMapping = urlMappings.find(mapping => mapping.accessor === request_params.collection_name);
                          if (!urlMapping.isAllowed) {
                            e.preventDefault()
                            e.stopPropagation()
                            onBlurColumnClick();
                          }
                        }}

                        to={generateUrl(request_params?.collection_name, hit.document.company_id || hit.document.investor_id, hit.document?.deal_id)}>
                        {hit.document.company__name || hit.document.name || hit.document.full_name || hit.document.fund__name}
                        {hit.document.date ? (<span className="bg-gradient-to-br from-sky-100 to-red-100 py-0.5 px-2 rounded text-gray-500 text-xs ml-1 ">{asHumanDate(hit.document.date)}</span>) : ""}
                        {hit.document.deal_round ? (<span className="bg-gradient-to-br from-red-100 to-amber-100 py-0.5 px-2 rounded text-gray-500 text-xs ml-1 ">{hit.document.deal_round}</span>) : ""}
                      </NavLink>
                    </div>
                    <div className="ml-11">
                      {getDescription(hit.document) ? (
                        <Tooltip
                          placement="bottom"
                          color="#ffffff"
                          key="#ffffff"
                          overlayStyle={{ maxWidth: '30%' }}
                          title={<p className=" bg-white text-black border border-white">{getFirstNonEmptyField(hit.document)}</p>}>

                          <p className="text-sm text-black whitespace-nowrap overflow-hidden overflow-ellipsis w-[36rem] hover:cursor-help">
                            {getFirstNonEmptyField(hit.document)}
                          </p>
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>

                  <div className="md:col-span-1 flex flex-col pr-2">
                    {highlightsSectionHandler(hit)}
                  </div>
                </div>

              ))}
            </div>
          </div>
        </div>
      </div>
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </>
  );
};
