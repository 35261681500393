import { createContext , useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import './Components/Font/Sequel Sans Light Body.ttf'
import './Components/Font/Sequel Sans Bold Body.ttf'
import './Components/Font/Sequel Sans Book Body.ttf'
import './Components/Font/Sequel Sans Book Obl Body.ttf'
import './Components/Font/Sequel Sans Medium Body.ttf'
import './Components/Font/Sequel Sans Roman Body.ttf'
import './Components/Font/Sequel Sans Semi Bold Body.ttf'

import PrivateRoute from './Components/Routes/PrivateRoute';
import PublicRoute from './Components/Routes/PublicRoute';

import Login from './Pages/Login'
import IPLogin from './Pages/IPLogin'
import Dashboard from './Pages/Dashboard'
import Home from './Pages/PeHome'
import REHome from './Pages/RE/ReHome'
import MAHOME from './Pages/MA/MaHome'
import CFS from './Pages/CFS'
import Reports from './Pages/Reports'
import AdminFilters from './Pages/Admin/AdminFilters'
import Notes from './Pages/Notes/NotePage'
import QueryBuilder from './Pages/QueryBuilder'
import Requests from './Pages/Requests/RequestPage'
import About from './Pages/About'

import PEAngelInvestment from './Pages/PE/AngelInvestment'
import Exists from './Pages/PE/Exists';
import ExistsViaMA from './Pages/PE/ExistsViaMA'
import ExistsViaPM from './Pages/PE/ExistsViaPM'
import VC_Exists from "./Pages/PE/Exits_VC"
import ExistsViaMAVCMA from './Pages/PE/ExistsViaMAVCMA'
import ExistsViaPMVCPM from './Pages/PE/ExistsViaPMVCPM'
import PEBackedIpo from './Pages/PE/BackedIpo'
import PEBackedIpoVc from './Pages/PE/BackedIpoVc'
import Incubation from './Pages/PE/Incubation';
import Vc from './Pages/PE/Vc';
// import PEVc from './Pages/PE/PEVc'

/* rehome */
import REBackedIpo from './Pages/REHomeMenu/BackedIpo'
import PEREExists from './Pages/REHomeMenu/Exists';
import REMa from './Pages/REHomeMenu/OtherMA'
import PERe from './Pages/REHomeMenu/PERe';

// reinvestor
import REVC from './Pages/REInvestorMenu/REVC';
import Exits from './Pages/REInvestorMenu/Exits';
import REInvestorBackedIpo from './Pages/REInvestorMenu/REInvestorBackedIpo';
import ReInvestorMa from './Pages/REInvestorMenu/AngelInvestment';

// re Advisor

import AdvisorPEVC from './Pages/REAdvisorMenu/AdvisorPEVC'
import AdvisorExits from './Pages/REAdvisorMenu/AdvisorExits';
import AdvisorBackedIpo from './Pages/REAdvisorMenu/AdvisorBackedIpo'
import AdvisorMA from './Pages/REAdvisorMenu/AdvisorMA';

// re Transaction Advisor
import TransactionPEVC from './Pages/RETransactionMenu/TransactionPEVC';
import TransactionExits from './Pages/RETransactionMenu/TransactionExits';
import TransactionMA from './Pages/RETransactionMenu/TransactionMA';
import TransactionBackedIpo from './Pages/RETransactionMenu/TransactionBackedIpo'

// cfs

import CfsDetailsPage from './Pages/CFSDetailsPage/DetailPage'

import NewDetailPage from './Pages/NewDetailPage'
import DetailPage from './Pages/DetailPage'
import FillingsDetailPage from "./Pages/FillingsDetails"
import DealPage from './Pages/DealPage'
import CompareCompany from './Pages/CompareCompany'
import CompareCompanyPopup from './Pages/ComparePopUp';

import AngelDeal from "./Pages/AngelDealPage/AngelDealPage"
import IncubationDeal from './Pages/IncubationDealPage/IncubationDealPage';
import M_ADealPage from './Pages/MA/CompanyDeal';

import Advisor from './Pages/Advisor/LegalAdvisor/Advisor'
import AdvisorVc from "./Pages/Advisor/LegalAdvisor/AdvisorVC"
import AdvisorExistsViaMAVCMA from './Pages/Advisor/LegalAdvisor/AdvisorExistsViaMAVCMA'
import AdvisorExistsViaPMVCPM from './Pages/Advisor/LegalAdvisor/AdvisorExistsViaPMVCPM'
import AdvisorExistsViaMA from "./Pages/Advisor/LegalAdvisor/AdvisorExistsViaMA"
import AdvisorExistsViaPM from "./Pages/Advisor/LegalAdvisor/AdvisorExistsViaPM"
import AdvisorExists from './Pages/Advisor/LegalAdvisor/AdvisorExits'
import AdvisorExistsVc from './Pages/Advisor/LegalAdvisor/VcAdvisorExits'




import TransactionAdvisor from './Pages/Advisor/TransactionAdvisor/TransactionAdvisor';
import TransactionAdvisorVc from "./Pages/Advisor/TransactionAdvisor/TransactionAdvisorVC"
import TransactionAdvisorRe from "./Pages/Advisor/TransactionAdvisor/TransactionAdvisorRE"
import TransactionAdvisorExistsViaMAVCMA from './Pages/Advisor/TransactionAdvisor/TransactionAdvisorExistsViaMAVCMA'
import TransactionAdvisorExistsViaPMVCPM from './Pages/Advisor/TransactionAdvisor/TransactionAdvisorExistsViaPMVCPM'
import TransactionAdvisorExistsViaMA from "./Pages/Advisor/TransactionAdvisor/TransactionAdvisorExistsViaMA"
import TransactionAdvisorExistsViaPM from "./Pages/Advisor/TransactionAdvisor/TransactionAdvisorExistsViaPM"
import TransactionAdvisorExists from "./Pages/Advisor/TransactionAdvisor/TransactionAdvisorExits"
import TransactionAdvisorExistsVc from './Pages/Advisor/TransactionAdvisor/VcTransactionAdvisorExits'




import AdvisorCompanyPage from "./Pages/Advisor/LegalAdvisor/AdvisorDetailPage"
import TransactionAdvisorCompanyPage from './Pages/Advisor/TransactionAdvisor/TransactionDetailPage';


import Investor from './Pages/Investor/Investor'
import InvestorExists from './Pages/Investor/PE-VC_Exit/PE_VC_Exits'
import InvestorVc from "./Pages/Investor/VC_Investments/VC_Investment"
import InvestorExistsViaPM from "./Pages/Investor/Exit_Public_PE-VC/Public_PE-VC"
import InvestorExistsViaMAVCMA from './Pages/Investor/M&A_VC/M&A_VC'
import InvestorExitsVC from "./Pages/Investor/M&A_VC/Exits_VC"
import InvestorExistsViaPMVCPM from './Pages/Investor/Publilc_VC/Public_vc'
import InvestorExistsViaMA from "./Pages/Investor/Exit_M&A_PE-VC/M&A_pe-vc"
import InvestorBackedIpo from './Pages/Investor/PE-VC_BackedIpos/BackedIpos'
import InvestorBackedIpoVc from './Pages/Investor/VC_BackedIpos/VC_BackedIpos'
import InvestorAngelInvestment from "./Pages/Investor/AngelInvestment/AngelInvestment"
import InvestorIncubation from './Pages/Investor/Incubation/Incubation'

import InvestorLimitedPartnerPe from "./Pages/Investor/Limited_Partner"
import InvestorLimitedPartnerVc from "./Pages/Investor/LimitedPartnerVc"
import InvestorLimitedPartnerExists from "./Pages/Investor/LimitedPartnerExits"
import InvestLimitedPartnerExitsVC from "./Pages/Investor/LimitedPartnerExitsVc"
import InvestorLimitedPartnerExistsMaPe from './Pages/Investor/LimitedPartnerExitsMaPe'
import InvestorLimitedPartnerExistsPmPe from "./Pages/Investor/LimitedPartnerExitsPmPe"
import InvestorLimitedPartnerExistsMaVc from './Pages/Investor/LimitedPartnerExitsMaVc'
import InvestorLimitedPartnerExistsPmVc from './Pages/Investor/LimitedPartnerExitsPmVc'

import InvestorLimitedPartnerDetail from "./Pages/Investor/LimitedPartnerDetail"

// InvestorFunds
import InvestorFundsPe from "./Pages/Investor/InvestorFunds"
import FundsDealDetails from "./Pages/Investor/FundsDetails"
import InvestorFundsVc from "./Pages/Investor/InvestorFundsVc"

import InverstorDetailPage from './Pages/Investor/InvestorDetail';
import InvestorExitDetailPage from './Pages/Investor/InvestorExitDetail';
import InvestorIPODetailPage from './Pages/Investor/InvestorIPODetail';
import InverstorIncubationDetailPage from './Pages/Investor/InvestorIncubationDetail'
import InverstorAngelDetailPage from './Pages/Investor/InvestorAngelDetail';


import REInvestor from './Pages/RE/ReInvestor';
import ReInvestorDetail from './Pages/RE/ReInvestorDetail'

import REAdvisor from './Pages/RE/ReAdvisor'
import ReAdvisorDetail from './Pages/RE/ReAdvisorDetail';

import REDetailPage from './Pages/RE/DetailPage'
import REDealPage from './Pages/RE/DealPage'


import MADetailPage from './Pages/MA/CompanyDetail'
import Acquirer from './Pages/MA/Acquirers/Acquirers'
import AcquirerDetail from './Pages/MA/Acquirers/DetailPage'
import AcquirerExists from './Pages/MA/Acquirers/Exists';

import MaAdvisors from './Pages/MA/Advisors/LegalAdvisors'
import MaAdvisorsDetail from './Pages/MA/Advisors/LegalAdvisorDetail'

import MaTransaction from './Pages/MA/Advisors/TransactionAdvisor'
import MaTransactionDetail from './Pages/MA/Advisors/TransactionAdvisorDetail'

import RETransactionAdvisor from './Pages/RE/ReTransactionAdvisor'
import ReTransactionDetail from './Pages/RE/ReTransactionDetail'

import CFSHome from './Pages/CFS/CfsHome';

import PeFAQ from './Pages/FAQ/PeFaq'
import ReFAQ from './Pages/FAQ/ReFaq'
import MaFAQ from './Pages/FAQ/MaFaq'
import CfsFAQ from './Pages/FAQ/CfsFaq'

import Popup from './Pages/addNote'

import Others from './Pages/Others'

import BalanceSheet from './Pages/Financial/BalanceSheet'
import CashFlow from './Pages/Financial/CashFlow'
import Ratios from './Pages/Financial/Ratios'
import McaSite from './Pages/Financial/McaSite'
import AccountSetting from './Pages/Setting/Setting';
import AppLayout from './AppLayout';
// import Header from './Components/HeaderComponent';
import routePathSegments, { routeNames } from './routeSegments';
// import PEHeader from './Components/Headers/PEHeader';


// vc menu
import AngelInvestmentVc from './Pages/PE/AngelInvestmentVc'
import IncubationVc from './Pages/PE/IncubationVc'

// vc Investor menu
import InvestorAngelInvestmentVc from './Pages/Investor/AngelInvestment/VcAngelInvestment'
import InvestortIncubationVc from './Pages/Investor/Incubation/Incubation/InvestorIncubationVc'
import RequestDetails from './Pages/Requests/RequestsDetailPage';
import ProtectedRoute from './Components/Routes/ProtectedRoute';
import DeviceAuthorization from './Pages/DeviceAuth/DeviceAuthorization';
import NewInvestor from './Pages/Investor/NewInvestor';
import Constants from './Constants';
import ActiveInvestors from './Pages/Investor/NewInvestor/ActiveInvestors';

// RE investors
import ReInvestments from './Pages/RE/Investor/pages/ReInvestments';
import ReInvestorExits from './Pages/RE/Investor/pages/ReInvestorExits';
import ReInvestorIpo from './Pages/RE/Investor/pages/ReInvestorIpo';
//re investor funds
import ReInvestorFunds from './Pages/RE/Investor/ReInvestorFunds'
import ReFundDetails from './Pages/RE/Investor/pages/ReFundDetails'
import PeFilterProvider from './Contexts/PeFilterContext';
import InvestorsQueryBuilder from './Pages/InvestorsQueryBuilder';
import LegalAdvisorsQueryBuilder from './Pages/LegalAdvisorsQueryBuilder';
import TransactionAdvisorsQueryBuilder from './Pages/TransactionAdvisorsQueryBuilder';
import InvestorCo_Investors from './Components/Investor/InvestorDetailPage/InvestorCo_Investors';
import FAQLandingPage from './Pages/FAQ/FAQLandingPage';
import ResetPassword from './Pages/ResetPassword';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
// import { pageview } from 'react-ga4'; // Incorrect import


// GOOGLE ANALYTICS SETUPS
import ReactGA from 'react-ga4';
import NewCFSLayout from './Pages/CFSDetailsPage/NewCFSLayout';
import NewSearchResult from './Pages/NewSearchResult';

const Measurement_ID = process.env.REACT_APP_GA_MID ;

ReactGA.initialize( Measurement_ID);



export const FilterContext = createContext('');



/** This code is defining routes for a application using the React Router library.
It is using the `<Route>` component to define different paths and their corresponding
components to render. */
function App() {
  // const [filterValue, setFilterValue] = useState("")
  const location = useLocation()
  

  // const filterHandler = useCallback((value) => {
  //   setFilterValue(value)
  // }, [filterValue])

  useEffect(() => {
    // Track page view for each route change
    window.gtag('event', 'page_view', {
      page_path: location.pathname,
      page_title: document.title,
    });
    // console.log("page_view")
  }, [location]);

  return (
    // <Router>
    <ErrorBoundary>
      <Routes>
      
        <Route path={routeNames.login} element={<ProtectedRoute> <Login /></ProtectedRoute>} />
        <Route path={`${routePathSegments.password}/${routePathSegments.reset}`} element={<ProtectedRoute><ResetPassword /></ProtectedRoute>} />
        <Route path={routeNames.ip_login} element={<ProtectedRoute> <IPLogin /></ProtectedRoute>} />
        <Route path={routeNames.device_authorization} element={<ProtectedRoute><DeviceAuthorization /></ProtectedRoute>} />
        <Route path="/" element={<PeFilterProvider> <PrivateRoute><AppLayout /></PrivateRoute></PeFilterProvider>}>
          <Route index element={<Navigate to={routePathSegments.login} replace />} />
          <Route path={routePathSegments.dashboard} element={<Dashboard  />} />
          <Route path={`${routePathSegments.globalSearch}/:type/:input`} element={<NewSearchResult />} />

          <Route path={routePathSegments.cfs}>
            <Route index element={<CFSHome />} replace />
            <Route path='*' element={<Navigate to={routePathSegments.cfs} replace />} />
            <Route path={`${routePathSegments.company}/old/:companyId`} element={<CfsDetailsPage />} />
            <Route path={`${routePathSegments.company}/:companyId`} element={<NewCFSLayout />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.pe_vc_investments}/:companyId/:dealId`} element={<DealPage />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.exists}/:companyId/:dealId`} element={<DealPage />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.backed_ipos}/:companyId/:dealId`} element={<DealPage />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.angel}/:companyId/:dealId`} element={<DealPage />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.incubation}/:companyId/:dealId`} element={<DealPage />} />
          </Route>

          <Route path={routePathSegments.setting} element={<AccountSetting />} />
          <Route path={routePathSegments.notes} element={<Notes />} />
          <Route path={routePathSegments.reports} element={<Reports />} />
          <Route path={`/${routePathSegments.pe}/${routePathSegments.investor}/${routePathSegments.coInvestor}/:investorId`} element={<InvestorCo_Investors investorId={location?.state?.investorId} primaryMasterId={location?.state?.typeId}/>} />          
          <Route path={`/${routePathSegments.vc}/${routePathSegments.investor}/${routePathSegments.coInvestor}/:investorId`} element={<InvestorCo_Investors investorId={location?.state?.investorId} primaryMasterId={location?.state?.typeId}/>} />          
          
          {/* advance query builder */}
          <Route path={`${routePathSegments.pe}/${routePathSegments.query_builder}/${routePathSegments.companies}`} element={<QueryBuilder />} />
          <Route path={`${routePathSegments.pe}/${routePathSegments.query_builder}/${routePathSegments.investors}`} element={<InvestorsQueryBuilder />} />
          <Route path={`${routePathSegments.pe}/${routePathSegments.query_builder}/${routePathSegments.advisors}`} element={<LegalAdvisorsQueryBuilder />} />
          <Route path={`${routePathSegments.pe}/${routePathSegments.query_builder}/${routePathSegments.transaction_advisors}`} element={<TransactionAdvisorsQueryBuilder />} />
          
          <Route path={`${routePathSegments.vi_admin}/${routePathSegments.admin_filters}`} element={<AdminFilters />} />

          <Route path='*' element={<Navigate to={'/'} />} />
          {/* <Route path={routePathSegments.faq}>
            <Route path='*' element={<Navigate to={routePathSegments.cfs_faq} replace />} />

            <Route path={'*'} element={<FAQLandingPage />} />
            <Route path={routePathSegments.cfs_faq} element={<CfsFAQ />} />
            <Route path={routePathSegments.pe_faq} element={<PeFAQ />} />
            <Route path={routePathSegments.re_faq} element={<ReFAQ />} />
            <Route path={routePathSegments.ma_faq} element={<MaFAQ />} />
          </Route> */}

          <Route path={routePathSegments.requests} element={<Requests />} />
          <Route path={routePathSegments.alerts} element={<Requests />} />
          <Route path={routePathSegments.notifications} element={<Requests />} />
          <Route path={`${routePathSegments.raise_request}/:requestId`} element={<RequestDetails />} />


          <Route path={routePathSegments.company}>

            <Route path=':companyId' element={<DetailPage />} />
            <Route path=':companyId/:dealId' element={<DealPage />} />
            <Route path={`${routePathSegments.fillings}/:companyId`} element={< FillingsDetailPage menu="" />} />
            {/* <Route path={`${routePathSegments.pe_vc_investments}/:companyId`} element={<DetailPage />} /> */}
            <Route path={`${routePathSegments.pe_vc_investments}/:companyId`} element={<DetailPage from={routePathSegments.pe_vc_investments} />} />
            <Route path={`${routePathSegments.exists}/:companyId`} element={<DetailPage from={routePathSegments.exists} />} />
            <Route path={`${routePathSegments.ipos}/:companyId`} element={<DetailPage from={routePathSegments.ipos} />} />
            <Route path={`${routePathSegments.angel_invesments}/:companyId`} element={<DetailPage from={routePathSegments.angel_invesments} />} />
            <Route path={`${routePathSegments.incubation}/:companyId`} element={<DetailPage from={routePathSegments.incubation} />} />

          </Route>

          <Route path={routePathSegments.investor}>
            <Route path=":investorId" element={<InverstorDetailPage />} />
          </Route>
          
          <Route path={`${routePathSegments.investor}/${routePathSegments.incubation}`}>
            <Route path=":investorId" element={<InverstorIncubationDetailPage />} />
          </Route>

          <Route path={`${routePathSegments.investor}/${routePathSegments.angel}`}>
            <Route path=":investorId" element={<InverstorAngelDetailPage />} />
          </Route>

          <Route path={`${routePathSegments.investor}/${routePathSegments.investment}`}>
            <Route path=":investorId" element={<InverstorDetailPage />} />
          </Route>

          <Route path={`${routePathSegments.investor}/${routePathSegments.exit}`}>
            <Route path=":investorId" element={<InvestorExitDetailPage />} />
          </Route>

          <Route path={`${routePathSegments.investor}/${routePathSegments.ipo}`}>
            <Route path=":investorId" element={<InvestorIPODetailPage />} />
          </Route>
          <Route path={`${routePathSegments.investor}/${routePathSegments.lp}`}>
            <Route path=":investorId" element={<InvestorLimitedPartnerDetail />} />
          </Route>

          <Route path={routePathSegments.advisor}>
            <Route path={`${routePathSegments.pe_vc_advisors}/:companyId`} element={<AdvisorCompanyPage menu='pe'/>} />
          </Route>

          <Route path={routePathSegments.transaction_advisor}>
            <Route path=':companyId' element={<TransactionAdvisorCompanyPage />} />
          </Route>

          <Route path={routePathSegments.re_company_page}>
            <Route path=":companyId" element={<REDetailPage />} />
          </Route>

          <Route path={`${routePathSegments.cfs_company_page}/:companyId`} element={<CfsDetailsPage />} />

          {/* <Route path={routePathSegments.re_deal_page} element={<REDealPage />} /> */}
          <Route path={routePathSegments.incubation_dealpage} element={<IncubationDeal />} />

          <Route path={routePathSegments.reinvestor}>
            <Route path=":reinvestorId" element={<ReInvestorDetail />} />
          </Route>

          <Route path={routePathSegments.readvisor}>
            <Route path=":readvisorId" element={<ReAdvisorDetail />} />
          </Route>

          <Route path={routePathSegments.retransaction}>
            <Route path=":retransactionId" element={<ReTransactionDetail />} />
          </Route>


          <Route path={`${routePathSegments.ma_company_page}/:companyId`} element={<MADetailPage />} />
          <Route path={`${routePathSegments.ma_dealpage}/:companyId/:dealId`} element={<M_ADealPage />} />
          <Route path={`${routePathSegments.ma_advisors}/:advisorsId`} element={<MaAdvisorsDetail />} />
          <Route path={`${routePathSegments.ma_acquirers}/:acquirersId`} element={<AcquirerDetail />} />
          <Route path={`${routePathSegments.ma_transaction}/:acquirersId`} element={<MaTransactionDetail />} />


          <Route path={routePathSegments.pe}>

            <Route path={`${routePathSegments.company}/${routePathSegments.fillings}/:companyId`} element={< FillingsDetailPage menu="pe" />} />
            <Route index element={<Navigate to={routePathSegments.companies} replace />} />
            <Route path='*' element={<Navigate to={routePathSegments.companies} replace />} />

            <Route path={`${routePathSegments.company}/:companyId`} element={<NewDetailPage menu="pe" />} />
            <Route path={`${routePathSegments.company}/old/:companyId`} element={<DetailPage menu="pe" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.pe_vc_investments}/:companyId/:dealId`} element={<DealPage menu="pe" subMenu="pe_vc_investments"/>} />
            <Route path={`${routePathSegments.company}/${routePathSegments.exists}/:companyId/:dealId`} element={<DealPage menu="pe" subMenu="exits"/>} />
            <Route path={`${routePathSegments.company}/${routePathSegments.backed_ipos}/:companyId/:dealId`} element={<DealPage menu="pe" subMenu="ipos"/>} />
            <Route path={`${routePathSegments.company}/${routePathSegments.angel}/:companyId/:dealId`} element={<DealPage menu="pe" subMenu="angel"/>} />
            <Route path={`${routePathSegments.company}/${routePathSegments.incubation}/:companyId/:dealId`} element={<DealPage menu="pe" subMenu="incubation"/>} />

            {/* Investor details */}
            <Route path={`${routePathSegments.investor}/:investorId`} element={<InverstorDetailPage menu="investment" />} />
            <Route path={`${routePathSegments.investor}/${routePathSegments.exit}/:investorId`} element={<InvestorExitDetailPage menu="exit" />} />
            <Route path={`${routePathSegments.investor}/${routePathSegments.ipo}/:investorId`} element={<InvestorIPODetailPage menu="ipo" />} />
            <Route path={`${routePathSegments.investor}/${routePathSegments.angel}/:investorId`} element={<InverstorAngelDetailPage menu="angle" />} />
            <Route path={`${routePathSegments.investor}/${routePathSegments.incubation}/:investorId`} element={<InverstorIncubationDetailPage menu="incubation" />} />
            {/* <Route path={`${routePathSegments.investor}/:investorId`} element={<InverstorDetailPage menu="pe" />} /> */}
            {/* <Route path={`${routePathSegments.investor}/${routePathSegments.pe_vc_investments}/:investorId/:dealId`} element={<DealPage menu="pe"/>} />
              <Route path={`${routePathSegments.investor}/${routePathSegments.exists}/:investorId/:dealId`} element={<DealPage />} />
              <Route path={`${routePathSegments.investor}/${routePathSegments.backed_ipos}/:investorId/:dealId`} element={<DealPage />} />
              <Route path={`${routePathSegments.investor}/${routePathSegments.angel}/:investorId/:dealId`} element={<DealPage />} />
              <Route path={`${routePathSegments.investor}/${routePathSegments.incubation}/:investorId/:dealId`} element={<DealPage />} /> */}

            {/* lp details */}
            <Route path={`${routePathSegments.investor}/:partnerId`} element={<InvestorLimitedPartnerDetail menu="pe" />} />

            {/* Advisor details */}
            <Route path={`${routePathSegments.advisor}/:companyId`} element={<AdvisorCompanyPage menu="pe"/>} />

            {/* Transaction advisor */}
            <Route path={`${routePathSegments.transaction_advisor}/:companyId`} element={<TransactionAdvisorCompanyPage />} />

            {/* Companies */}
            <Route path={routePathSegments.companies}>
              <Route index element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />

              <Route path={routePathSegments.pe_vc_investments} element={<Home />}  />
              <Route path={routePathSegments.angel_invesments} element={<PEAngelInvestment />} />
              <Route path={routePathSegments.incubation} element={<Incubation />} />
              <Route path={`${routePathSegments.backed_ipos}/${routePathSegments.pe_vc_investments}`} element={<PEBackedIpo />} />

              <Route path={routePathSegments.exists}>
                <Route index element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
                <Route path='*' element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
                <Route path={routePathSegments.pe_vc_investments} element={<Exists />} />
                <Route path={`${routePathSegments.ma}/${routePathSegments.pe_vc_investments}`} element={<ExistsViaMA />} />
                <Route path={`${routePathSegments.pm}/${routePathSegments.pe_vc_investments}`} element={<ExistsViaPM />} />
              </Route>

            </Route>

            {/* Investors */}
            <Route path={routePathSegments.investors}>
              <Route index element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
              
              <Route path={routePathSegments.limited_partners}>
                <Route path=':partnerId' element={<InvestorLimitedPartnerDetail menu="pe" />} />
              </Route>

              <Route path={routePathSegments.funds_detail_page}  >
                <Route path=':investorfundId/:dealId' element={<FundsDealDetails menu="pe" />} />
              </Route>

              <Route path={routePathSegments.pe_vc_funds} element={<InvestorFundsPe />} />

              <Route path={routePathSegments.pe_vc_investments} element={<Investor />} />
              <Route path={routePathSegments.angel_invesments} element={<InvestorAngelInvestment />} />
              <Route path={routePathSegments.incubation} element={<InvestorIncubation />} />
              <Route path={`${routePathSegments.backed_ipos}/${routePathSegments.pe_vc_investments}`} element={<InvestorBackedIpo />} />
              <Route path={routePathSegments.pe_vc_limited_partners} element={<InvestorLimitedPartnerPe />} />

              <Route path={routePathSegments.exists}>
                <Route index element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
                <Route path='*' element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
                <Route path={routePathSegments.pe_vc_investments} element={<InvestorExists />} />
                <Route path={routePathSegments.pe_vc_limited_partners} element={<InvestorLimitedPartnerExists />} />

                <Route path={routePathSegments.ma}>
                  <Route index element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
                  <Route path='*' element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
                  <Route path={routePathSegments.pe_vc_investments} element={<InvestorExistsViaMA />} />
                  <Route path={routePathSegments.pe_vc_limited_partners} element={<InvestorLimitedPartnerExistsMaPe />} />
                </Route>

                <Route path={routePathSegments.pm}>
                  <Route index element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
                  <Route path='*' element={<Navigate to={routePathSegments.pe_vc_investments} replace />} />
                  <Route path={routePathSegments.pe_vc_investments} element={<InvestorExistsViaPM />} />
                  <Route path={routePathSegments.pe_vc_limited_partners} element={<InvestorLimitedPartnerExistsPmPe />} />
                </Route>

              </Route>

            </Route>
            <Route path={routePathSegments.newInvestors}>
              <Route path={routePathSegments.pe_vc_investments} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_investments_id} key="investments" menu="investments" />} />
              <Route path={routePathSegments.exists} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_exits_id} menu="exits" key="exits" />} />
              <Route path={`${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.pe_vc_investments}`} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_ma_id} menu="exits_ma" key="exits_ma" />} />
              <Route path={`${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.pe_vc_investments}`} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_pm_id} menu="exits_pm" key="exits_pm" />} />
              <Route path={routePathSegments.angel} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_angel_invesments_id} menu="angels" key="angels" />} />
              <Route path={routePathSegments.incubation} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_incubation_id} menu="incubation" key="incubation" />} />
            </Route>

            <Route path={routePathSegments.activeInvestors}>
              <Route path={routePathSegments.pe_vc_investments} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_investments_id} menu="investments" key="investments" />} />
              <Route path={routePathSegments.exists} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_exits_id} menu="exits" key="exits" />} />
              <Route path={`${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.pe_vc_investments}`} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_ma_id} menu="exits_ma" key="exits_ma" />} />
              <Route path={`${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.pe_vc_investments}`} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_pm_id} menu="exits_pm" key="exits_pm" />} />
              <Route path={routePathSegments.angel} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_angel_invesments_id} menu="angels" key="angels" />} />
              <Route path={routePathSegments.incubation} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.peId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_incubation_id} menu="incubation" key="incubation" />} />
            </Route>

            {/* Advisors */}
            <Route path={routePathSegments.advisors}>
              <Route index element={<Navigate to={routePathSegments.pe_vc_advisors} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.pe_vc_advisors} replace />} />

              <Route path={routePathSegments.pe_vc_advisors} element={<Advisor />} />
              <Route path={`${routePathSegments.ma_exits}/${routePathSegments.pe_vc_advisors}`} element={<AdvisorExists />} />
              <Route path={`${routePathSegments.ma}/${routePathSegments.pe_vc_advisors}`} element={<AdvisorExistsViaMA />} />
              <Route path={`${routePathSegments.pm}/${routePathSegments.pe_vc_advisors}`} element={<AdvisorExistsViaPM />} />
            </Route>

            {/* Transaction advisors */}
            <Route path={routePathSegments.transaction_advisors}>
              <Route index element={<Navigate to={routePathSegments.pe_vc_advisors} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.pe_vc_advisors} replace />} />


              <Route path={routePathSegments.pe_vc_advisors} element={<TransactionAdvisor />} />
              <Route path={`${routePathSegments.ma_exits}/${routePathSegments.pe_vc_advisors}`} element={<TransactionAdvisorExists />} />
              <Route path={`${routePathSegments.ma}/${routePathSegments.pe_vc_advisors}`} element={<TransactionAdvisorExistsViaMA />} />
              <Route path={`${routePathSegments.pm}/${routePathSegments.pe_vc_advisors}`} element={<TransactionAdvisorExistsViaPM />} />
            </Route>

          </Route>

          {/* VC */}
          <Route path={routePathSegments.vc} >

            <Route path={`${routePathSegments.company}/${routePathSegments.fillings}/:companyId`} element={< FillingsDetailPage menu="vc" />} />
            <Route index element={<Navigate to={routePathSegments.companies} replace />} />
            <Route path='*' element={<Navigate to={routePathSegments.companies} replace />} />

            <Route path={`${routePathSegments.company}/:companyId`} element={<NewDetailPage menu="vc" />} />
            <Route path={`${routePathSegments.company}/old/:companyId`} element={<DetailPage menu="vc" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.vc_investments}/:companyId/:dealId`} element={<DealPage menu="vc" subMenu="pe_vc_investments"/>} />
            <Route path={`${routePathSegments.company}/${routePathSegments.exists}/:companyId/:dealId`} element={<DealPage menu="vc" subMenu="exits"/>} />
            <Route path={`${routePathSegments.company}/${routePathSegments.backed_ipos}/:companyId/:dealId`} element={<DealPage menu="vc" subMenu="ipos"/>} />
            <Route path={`${routePathSegments.company}/${routePathSegments.angel}/:companyId/:dealId`} element={<DealPage menu="vc" subMenu='angel'/>} />
            <Route path={`${routePathSegments.company}/${routePathSegments.incubation}/:companyId/:dealId`} element={<DealPage menu="vc" subMenu="incubation"/>} />

            {/* Investor details */}
            <Route path={`${routePathSegments.investor}/:investorId`} element={<InverstorDetailPage />} />
            <Route path={`${routePathSegments.investor}/${routePathSegments.exit}/:investorId`} element={<InvestorExitDetailPage menu="vc" />} />
            <Route path={`${routePathSegments.investor}/${routePathSegments.ipo}/:investorId`} element={<InvestorIPODetailPage menu="vc" />} />
            <Route path={`${routePathSegments.investor}/${routePathSegments.angel}/:investorId`} element={<InverstorAngelDetailPage menu="vc" />} />
            <Route path={`${routePathSegments.investor}/${routePathSegments.incubation}/:investorId`} element={<InverstorIncubationDetailPage menu="vc" />} />

            {/* Advisor details */}
            <Route path={`${routePathSegments.advisor}/:companyId`} element={<AdvisorCompanyPage menu='vc'/>} />

            {/* Transaction advisor */}
            <Route path={`${routePathSegments.transaction_advisor}/:companyId`} element={<TransactionAdvisorCompanyPage />} />


            {/* Companies */}
            <Route path={routePathSegments.companies}>
              <Route index element={<Navigate to={routePathSegments.vc_investments} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.vc_investments} replace />} />

              <Route path={routePathSegments.vc_investments} element={<Vc />} />
              <Route path={`${routePathSegments.backed_ipos}/${routePathSegments.vc_investments}`} element={<PEBackedIpoVc />} />
              <Route path={routePathSegments.angel_invesments} element={<AngelInvestmentVc />} />
              <Route path={routePathSegments.incubation} element={<IncubationVc />} />

              <Route path={routePathSegments.exists}>
                <Route index element={<Navigate to={routePathSegments.vc_investments} replace />} />
                <Route path='*' element={<Navigate to={routePathSegments.vc_investments} replace />} />
                <Route path={routePathSegments.vc_investments} element={<VC_Exists />} />
                <Route path={`${routePathSegments.ma}/${routePathSegments.vc_investments}`} element={<ExistsViaMAVCMA />} />
                <Route path={`${routePathSegments.pm}/${routePathSegments.vc_investments}`} element={<ExistsViaPMVCPM />} />
              </Route>

            </Route>

            {/* Investors */}
            <Route path={routePathSegments.investors}>
              <Route index element={<Navigate to={routePathSegments.vc_investments} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.vc_investments} replace />} />

              <Route path={routePathSegments.limited_partners}>
                <Route path=':partnerId' element={<InvestorLimitedPartnerDetail menu="vc" />} />
              </Route>

              <Route path={routePathSegments.funds_detail_page}  >
                <Route path=':investorfundId/:dealId' element={<FundsDealDetails menu="vc" />} />
              </Route>            
              

              {/* <Route path={routePathSegments.pe_vc_funds} element={<InvestorFundsPe />} />
                <Route path={routePathSegments.funds_detail_page} element={<FundsDealDetails />} /> */}
              <Route path={routePathSegments.vc_investments} element={<InvestorVc />} />
              <Route path={`${routePathSegments.backed_ipos}/${routePathSegments.vc_investments}`} element={<InvestorBackedIpoVc />} />
              <Route path={routePathSegments.vc_limited_partners} element={<InvestorLimitedPartnerVc />} />
              <Route path={routePathSegments.angel_invesments} element={<InvestorAngelInvestmentVc />} />
              <Route path={routePathSegments.incubation} element={<InvestortIncubationVc />} />
              <Route path={routePathSegments.vc_funds} element={<InvestorFundsVc />} />

              <Route path={routePathSegments.exists}>
                <Route path={routePathSegments.vc_investments} element={<InvestorExitsVC menu="exits_vc"/>} />
                <Route index element={<Navigate to={routePathSegments.ma} replace />} />
                <Route path='*' element={<Navigate to={routePathSegments.ma} replace />} />
                <Route path={routePathSegments.vc_limited_partners} element={<InvestLimitedPartnerExitsVC />} />
                <Route path={routePathSegments.ma}>
                  <Route index element={<Navigate to={routePathSegments.vc_investments} replace />} />
                  <Route path='*' element={<Navigate to={routePathSegments.vc_investments} replace />} />
                  <Route path={routePathSegments.vc_investments} element={<InvestorExistsViaMAVCMA />} />
                  <Route path={routePathSegments.vc_limited_partners} element={<InvestorLimitedPartnerExistsMaVc />} />
                </Route>

                <Route path={routePathSegments.pm}>
                  <Route index element={<Navigate to={routePathSegments.vc_investments} replace />} />
                  <Route path='*' element={<Navigate to={routePathSegments.vc_investments} replace />} />
                  <Route path={routePathSegments.vc_investments} element={<InvestorExistsViaPMVCPM />} />
                  <Route path={routePathSegments.vc_limited_partners} element={<InvestorLimitedPartnerExistsPmVc />} />
                </Route>

              </Route>
            </Route>

            <Route path={routePathSegments.newInvestors}>
              <Route path={routePathSegments.vc_investments} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_investments_id} menu="investments" key="investments" />} />
              <Route path={routePathSegments.exists} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_exits_id} menu="exits" key="exits" />} />
              <Route path={`${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.vc_investments}`} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_ma_id} menu="exits_ma" key="exits_ma" />} />
              <Route path={`${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.vc_investments}`} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_pm_id} menu="exits_pm" key="exits_pm" />} />
              <Route path={routePathSegments.angel} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_angel_invesments_id} menu="angels" key="angels" />} />
              <Route path={routePathSegments.incubation} element={<NewInvestor primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_incubation_id} menu="incubation" key="incubation" />} />
            </Route>

            <Route path={routePathSegments.activeInvestors}>
              <Route path={routePathSegments.vc_investments} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_investments_id} menu="investments" key="investments" />} />
              <Route path={routePathSegments.exists} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_exits_id} menu="exits" key="exits" />} />
              <Route path={`${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.vc_investments}`} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_ma_id} menu="exits_ma" key="exits_ma" />} />
              <Route path={`${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.vc_investments}`} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_pm_id} menu="exits_pm" key="exits_pm" />} />
              <Route path={routePathSegments.angel} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_angel_invesments_id} menu="angels" key="angels" />} />
              <Route path={routePathSegments.incubation} element={<ActiveInvestors primaryTypeMasterId={Constants.primaryMasterIds.vcId} primaryMasterSubId={Constants.primaryMasterSubIds.pe_vc_incubation_id} menu="incubation" key="incubation" />} />
            </Route>

            {/* Advisors */}
            <Route path={routePathSegments.advisors}>
              <Route index element={<Navigate to={routePathSegments.vc_advisors} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.vc_advisors} replace />} />

              <Route path={routePathSegments.vc_advisors} element={<AdvisorVc />} />
              <Route path={`${routePathSegments.ma_exits}/${routePathSegments.vc_advisors}`} element={<AdvisorExistsVc />} />

              <Route path={`${routePathSegments.ma}/${routePathSegments.vc_advisors}`} element={<AdvisorExistsViaMAVCMA />} />
              <Route path={`${routePathSegments.pm}/${routePathSegments.vc_advisors}`} element={<AdvisorExistsViaPMVCPM />} />
            </Route>

            {/* Transaction advisors */}
            <Route path={routePathSegments.transaction_advisors}>
              <Route index element={<Navigate to={routePathSegments.vc_advisors} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.vc_advisors} replace />} />


              <Route path={routePathSegments.vc_advisors} element={<TransactionAdvisorVc />} />
              <Route path={`${routePathSegments.ma_exits}/${routePathSegments.vc_advisors}`} element={<TransactionAdvisorExistsVc />} />

              <Route path={`${routePathSegments.ma}/${routePathSegments.vc_advisors}`} element={<TransactionAdvisorExistsViaMAVCMA />} />
              <Route path={`${routePathSegments.pm}/${routePathSegments.vc_advisors}`} element={<TransactionAdvisorExistsViaPMVCPM />} />
            </Route>

          </Route>

          {/* RE */}
          <Route path={routePathSegments.re} >

            {/* re company detail page */}

            <Route path={`${routePathSegments.company}/:companyId`} element={<REDetailPage />} />



            {/* RE investor detail page */}
            <Route path={`${routePathSegments.investor}/:reinvestorId`} element={<ReInvestorDetail  />} />

            <Route index element={<Navigate to={routePathSegments.companies} replace />} />
            <Route path='*' element={<Navigate to={routePathSegments.companies} replace />} />


            <Route path={routePathSegments.companies} >
              <Route index element={<Navigate to={routePathSegments.re_investments} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.re_investments} replace />} />

              <Route path={routePathSegments.re_investments} element={<REHome />} />
              <Route path={routePathSegments.re_exits} element={<PEREExists />} />
              <Route path={routePathSegments.other_ma} element={<REMa />} />
              <Route path={routePathSegments.re_ipo} element={<REBackedIpo />} />
            </Route>
            <Route path={`${routePathSegments.company}/:companyId`} element={<REDealPage menu="re" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.re_investments}/:companyId/:dealId`} element={<REDealPage menu="re" category="re-investments" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.re_exits}/:companyId/:dealId`} element={<REDealPage menu="re" category="re-exits" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.re_ipo}/:companyId/:dealId`} element={<REDealPage menu="re" category="re-ipo" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.other_ma}/:companyId/:dealId`} element={<REDealPage menu="re" category="other-manda " />} />

            {/* <Route path={routePathSegments.re_deal_page} element={<REDealPage />} /> */}


            {/* re investor */}

            <Route path={routePathSegments.investors} >

              <Route path={routePathSegments.re_funds_detail}  >
                <Route path=':reinvestorfundId/:dealId' element={<ReFundDetails menu="re" />} />
              </Route>

              <Route path={routePathSegments.re_funds} element={<ReInvestorFunds />} />

              <Route index element={<Navigate to={routePathSegments.re_investments} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.re_investments} replace />} />

              <Route path={routePathSegments.re_investments} element={<ReInvestments  />} />
              <Route path={routePathSegments.exists} element={<ReInvestorExits dataPath="re-exits"/>} />
              {/* <Route path={routePathSegments.other_ma} element={<ReInvestorIpo />} /> */}
              <Route path={routePathSegments.backed_ipos} element={<ReInvestorIpo  dataPath="re-ipo"/>} />
            </Route>



            {/*RE Advisor */}

            {/* advisor detail */}
            <Route path={`${routePathSegments.readvisor}/:companyId`} element={<ReAdvisorDetail />} />

            <Route path={routePathSegments.advisors} >

              <Route index element={<Navigate to={routePathSegments.re_investments} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.re_investments} replace />} />

              <Route path={routePathSegments.re_investments} element={<AdvisorPEVC />} />
              <Route path={routePathSegments.re_exits} element={<AdvisorExits dataPath="re-exits"/>} />
              <Route path={routePathSegments.other_ma} element={<AdvisorMA dataPath="other-manda"/>} />
              {/* <Route path={routePathSegments.re_ipo} element={<AdvisorBackedIpo />} /> */}
            </Route>

            {/* Re Transaction */}
            <Route path={`${routePathSegments.retransaction}/:companyId`} element={<ReTransactionDetail />} />

            <Route path={routePathSegments.transaction_advisors} >

              <Route index element={<Navigate to={routePathSegments.re_investments} replace />} />
              <Route path='*' element={<Navigate to={routePathSegments.re_investments} replace />} />

              <Route path={routePathSegments.re_investments} element={<TransactionPEVC />} />
              <Route path={routePathSegments.re_exits} element={<TransactionExits />} />
              <Route path={routePathSegments.other_ma} element={<TransactionMA />} />
              {/* <Route path={routePathSegments.re_ipo} element={<TransactionBackedIpo />} /> */}
            </Route>

          </Route>

          {/* MA */}
          <Route path={routePathSegments.ma} >

            <Route path={`${routePathSegments.company}/${routePathSegments.fillings}/:companyId`} element={< FillingsDetailPage menu="ma" />} />
            <Route index element={<Navigate to={routePathSegments.companies} replace />} />
            <Route path='*' element={<Navigate to={routePathSegments.companies} replace />} />

            {/* list page */}
            <Route path={routePathSegments.companies} element={<MAHOME />} />
            <Route path={routePathSegments.acquirers} element={<Acquirer />} />
            <Route path={routePathSegments.advisors} element={<MaAdvisors />} />
            <Route path={routePathSegments.transaction_advisors} element={<MaTransaction />} />

            {/* detailpage */}
            <Route path={`${routePathSegments.company}/:companyId`} element={<MADetailPage />} />
            <Route path={`${routePathSegments.acquirer}/:acquirerId`} element={<AcquirerDetail />} />
            <Route path={`${routePathSegments.advisor}/:advisorsId`} element={<MaAdvisorsDetail advisorType="L" />} />
            <Route path={`${routePathSegments.transaction_advisor}/:advisorsId`} element={<MaAdvisorsDetail advisorType="T" />} />


            {/* dealpage */}
            <Route path={`${routePathSegments.company}/:companyId/:dealId`} element={<M_ADealPage menu="ma" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.pe_vc_investments}/:companyId/:dealId`} element={<M_ADealPage menu="ma" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.exists}/:companyId/:dealId`} element={<M_ADealPage menu="ma" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.angel}/:companyId/:dealId`} element={<M_ADealPage menu="ma" />} />
            <Route path={`${routePathSegments.company}/${routePathSegments.incubation}/:companyId/:dealId`} element={<M_ADealPage menu="ma" />} />


            {/* <Route path={routePathSegments.companies}>
                <Route path={routePathSegments.re_investments} element={<MAHOME />} />
                <Route path={routePathSegments.exists} element={<p>Re Exists</p>} />
                <Route path={routePathSegments.other_ma} element={<p>Re Other</p>} />
                <Route path={routePathSegments.backed_ipos} element={<p>Re Other</p>} />
              </Route> */}

          </Route>
          <Route path={`${routePathSegments.compare}/${routePathSegments.company}/:companyId`} element={<PrivateRoute><CompareCompany module="company" /></PrivateRoute>} />
          <Route path={`${routePathSegments.compare}/${routePathSegments.investor}/:investorId`} element={<PrivateRoute><CompareCompany module="investor" /></PrivateRoute>} />
          <Route path={`${routePathSegments.compare}/${routePathSegments.advisor}/:companyId`} element={<PrivateRoute><CompareCompany module="l_advisor" /></PrivateRoute>} />
          <Route path={`${routePathSegments.compare}/${routePathSegments.transaction_advisor}/:companyId`} element={<PrivateRoute><CompareCompany module="t_advisor" /></PrivateRoute>} />
          <Route path={`${routePathSegments.compare}/${routePathSegments.acquirers}/:acquirerId`} element={<PrivateRoute><CompareCompany module="acquirers" /></PrivateRoute>} />
          <Route path="/compare-companies/:companyId" element={<PrivateRoute><CompareCompany /></PrivateRoute>} />

        </Route>
      </Routes>
    </ErrorBoundary>
    // </Router>
  );

  return (
    // <Fragment>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PublicRoute />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/recompanies" element={<PrivateRoute><REHome /></PrivateRoute>} />
        <Route path="/ma-companies" element={<PrivateRoute><MAHOME /></PrivateRoute>} />
        <Route path="/cfs" element={<PrivateRoute><CFS /></PrivateRoute>} />

        <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
        <Route path="/about" element={<PrivateRoute><About /></PrivateRoute>} />

        {/* pe menu pages*/}
        <Route path="/companies/pe-vc-investments" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/companies/vc-investments" element={<PrivateRoute><Vc /></PrivateRoute>} />
        <Route path="/companies/exists/pe-vc-investments" element={<PrivateRoute><Exists /></PrivateRoute>} />
        <Route path="/companies/exists/ma/pe-vc-investments" element={<PrivateRoute><ExistsViaMA /></PrivateRoute>} />
        <Route path="/companies/exists/pm/pe-vc-investments" element={<PrivateRoute><ExistsViaPM /></PrivateRoute>} />
        <Route path="/companies/exists/ma/vc-investments" element={<PrivateRoute><ExistsViaMAVCMA /></PrivateRoute>} />
        <Route path="/companies/exists/pm/vc-investments" element={<PrivateRoute><ExistsViaPMVCPM /></PrivateRoute>} />
        <Route path="/companies/backed-ipos/pe-vc-investments" element={<PrivateRoute><PEBackedIpo /></PrivateRoute>} />
        <Route path="/companies/backed-ipos/vc-investments" element={<PrivateRoute><PEBackedIpoVc /></PrivateRoute>} />
        <Route path="/companies/angel-invesments" element={<PrivateRoute><PEAngelInvestment /></PrivateRoute>} />
        <Route path="/companies/incubation" element={<PrivateRoute><Incubation /></PrivateRoute>} />
        <Route path="/companies/vc-angel-invesments" element={<PrivateRoute><AngelInvestmentVc /></PrivateRoute>} />
        <Route path="/companies/vc-incubation" element={<PrivateRoute><IncubationVc /></PrivateRoute>} />

        {/* rehome */}
        <Route path="/rehome-re" element={<PrivateRoute><PERe /></PrivateRoute>} />
        <Route path="/rehome-re/exists" element={<PrivateRoute><PEREExists /></PrivateRoute>} />
        <Route path="/rehome-re/other-ma" element={<PrivateRoute><REMa /></PrivateRoute>} />
        <Route path="/rehome-re/re-backed-ipo" element={<PrivateRoute><REBackedIpo /></PrivateRoute>} />

        {/* re investor menu pages */}
        <Route path="/reinvestor-re" element={<PrivateRoute><REVC /></PrivateRoute>} />
        <Route path="/reinvestor-re/exists" element={<PrivateRoute><Exits /></PrivateRoute>} />
        <Route path="/reinvestor-re/other-ma" element={<PrivateRoute><ReInvestorMa /></PrivateRoute>} />
        <Route path="/reinvestor-re/re-backed-ipo" element={<PrivateRoute>< REInvestorBackedIpo /></PrivateRoute>} />

        {/* re Advisor Main menu page */}
        <Route path="/readvisor" element={<PrivateRoute><AdvisorPEVC /></PrivateRoute>} />
        <Route path="/readvisor-re/exits" element={<PrivateRoute><AdvisorExits /></PrivateRoute>} />
        <Route path="/readvisor-re/other-ma" element={<PrivateRoute><AdvisorMA /></PrivateRoute>} />
        <Route path="/readvisor-re/re-backed-ipo" element={<PrivateRoute>< AdvisorBackedIpo /></PrivateRoute>} />


        {/* re TRansaction Advisor Main menu page */}
        <Route path="/retransaction" element={<PrivateRoute><TransactionPEVC /></PrivateRoute>} />
        <Route path="/retransaction-re/exits" element={<PrivateRoute><TransactionExits /></PrivateRoute>} />
        <Route path="/retransaction-re/other-ma" element={<PrivateRoute><TransactionMA /></PrivateRoute>} />
        <Route path="/retransaction-re/re-backed-ipo" element={<PrivateRoute>< TransactionBackedIpo /></PrivateRoute>} />



        {/* investor menu pages*/}
        <Route path="/investors/pe-vc-investments" element={<PrivateRoute><Investor /></PrivateRoute>} />
        <Route path="/investors/vc-investments" element={<PrivateRoute><InvestorVc /></PrivateRoute>} />
        <Route path="/investors/exists/pe-vc-investments" element={<PrivateRoute><InvestorExists /></PrivateRoute>} />
        <Route path="/investors/exists/ma/pe-vc-investments" element={<PrivateRoute><InvestorExistsViaMA /></PrivateRoute>} />
        <Route path="/investors/exists/pm/pe-vc-investments" element={<PrivateRoute><InvestorExistsViaPM /></PrivateRoute>} />
        <Route path="/investors/exists/ma/vc-investments" element={<PrivateRoute><InvestorExistsViaMAVCMA /></PrivateRoute>} />
        <Route path="/investors/exists/pm/vc-investments" element={<PrivateRoute><InvestorExistsViaPMVCPM /></PrivateRoute>} />
        <Route path="/investors/backed-ipos/pe-vc-investments" element={<PrivateRoute><InvestorBackedIpo /></PrivateRoute>} />
        <Route path="/investors/backed-ipos/vc-investments" element={<PrivateRoute><InvestorBackedIpoVc /></PrivateRoute>} />
        <Route path="/investors/angel-invesments" element={<PrivateRoute><InvestorAngelInvestment /></PrivateRoute>} />
        <Route path="/investors/incubation" element={<PrivateRoute><InvestorIncubation /></PrivateRoute>} />



        {/* investor limited partners */}
        {/* <Route path="/investors/limited-partners" element={<PrivateRoute><InvestorLimitedPartner /></PrivateRoute>} /> */}
        <Route path="/investors/pe-vc-limited-partners" element={<PrivateRoute><InvestorLimitedPartnerPe /></PrivateRoute>} />
        <Route path="/investors/vc-limited-partners" element={<PrivateRoute><InvestorLimitedPartnerVc /></PrivateRoute>} />
        <Route path="/investors/exists/pe-vc-limited-partners" element={<PrivateRoute><InvestorLimitedPartnerExists /></PrivateRoute>} />
        <Route path="/investors/exists/ma/pe-vc-limited-partners" element={<PrivateRoute><InvestorLimitedPartnerExistsMaPe /></PrivateRoute>} />
        <Route path="/investors/exists/pm/pe-vc-limited-partners" element={<PrivateRoute><InvestorLimitedPartnerExistsPmPe /></PrivateRoute>} />
        <Route path="/investors/exists/ma/vc-limited-partners" element={<PrivateRoute><InvestorLimitedPartnerExistsMaVc /></PrivateRoute>} />
        <Route path="/investors/exists/pm/vc-limited-partners" element={<PrivateRoute><InvestorLimitedPartnerExistsPmVc /></PrivateRoute>} />

        {/* investor limited partners detail page */}
        <Route path="/investors/limited-partners/:partnerId" element={<PrivateRoute><InvestorLimitedPartnerDetail /></PrivateRoute>} />

        {/* investor funds  */}

        <Route path="/investors/pe-vc-funds" element={<PrivateRoute><InvestorFundsPe /></PrivateRoute>} />
        {/* investor Funds Deal detail page */}
        <Route path="/investors/funds-deal-page" element={<PrivateRoute><FundsDealDetails /></PrivateRoute>} />


        <Route path="/cfs-companies" element={<PrivateRoute><CFSHome /></PrivateRoute>} />

        {/* CFS detail page */}

        <Route path="/cfs-company-page/:companyId" element={<PrivateRoute><CfsDetailsPage /></PrivateRoute>} />


        {/* legal advisor list pages */}
        <Route path="/advisors/pe-vc-advisors" element={<PrivateRoute><Advisor /></PrivateRoute>} />
        <Route path="/advisors/vc-advisors" element={<PrivateRoute><AdvisorVc /></PrivateRoute>} />
        <Route path="/advisors/ma/pe-vc-advisors" element={<PrivateRoute><AdvisorExistsViaMA /></PrivateRoute>} />
        <Route path="/advisors/pm/pe-vc-advisors" element={<PrivateRoute><AdvisorExistsViaPM /></PrivateRoute>} />
        <Route path="/advisors/ma/vc-advisors" element={<PrivateRoute><AdvisorExistsViaMAVCMA /></PrivateRoute>} />
        <Route path="/advisors/pm/vc-advisors" element={<PrivateRoute><AdvisorExistsViaPMVCPM /></PrivateRoute>} />




        {/* transaction advisor list pages */}
        <Route path="/transaction-advisor/pe-vc-advisors" element={<PrivateRoute><TransactionAdvisor /></PrivateRoute>} />
        <Route path="/transaction-advisor/vc-advisors" element={<PrivateRoute><TransactionAdvisorVc /></PrivateRoute>} />

        <Route path="/transaction-advisor/re-advisors" element={<PrivateRoute><TransactionAdvisorRe /></PrivateRoute>} />

        <Route path="/transaction-advisor/ma/pe-vc-advisors" element={<PrivateRoute><TransactionAdvisorExistsViaMA /></PrivateRoute>} />
        <Route path="/transaction-advisor/pm/pe-vc-advisors" element={<PrivateRoute><TransactionAdvisorExistsViaPM /></PrivateRoute>} />
        <Route path="/transaction-advisor/ma/vc-advisors" element={<PrivateRoute><TransactionAdvisorExistsViaMAVCMA /></PrivateRoute>} />
        <Route path="/transaction-advisor/pm/vc-advisors" element={<PrivateRoute><TransactionAdvisorExistsViaPMVCPM /></PrivateRoute>} />





        {/* Investor */}
        <Route path="/investor/:investorId" element={<PrivateRoute><InverstorDetailPage /></PrivateRoute>} />


        {/* ReInvestor */}
        <Route path="/reinvestor" element={<PrivateRoute><REInvestor /></PrivateRoute>} />
        <Route path="/reinvestor/:reinvestorId" element={<PrivateRoute><ReInvestorDetail /></PrivateRoute>} />
        {/* ReAdvisers */}
        <Route path="/readvisor" element={<PrivateRoute><REAdvisor /></PrivateRoute>} />
        <Route path="/readvisor/:readvisorId" element={<PrivateRoute><ReAdvisorDetail /></PrivateRoute>} />
        {/* ReTransactionAdvisor */}
        <Route path="/retransaction" element={<PrivateRoute><RETransactionAdvisor /></PrivateRoute>} />
        <Route path="/retransaction/:retransactionId" element={<PrivateRoute><ReTransactionDetail /></PrivateRoute>} />

        {/* Advisor */}
        {/* <Route path="/advisors/pe-vc-advisors" element={<PrivateRoute><Advisor /></PrivateRoute>} /> */}
        {/* <Route path="/transaction-advisor" element={<PrivateRoute><TransactionAdvisor /></PrivateRoute>} /> */}
        <Route path="/advisor/pe-vc-advisors/:companyId" element={<PrivateRoute><AdvisorCompanyPage /></PrivateRoute>} />
        <Route path="/transaction-advisor/:companyId" element={<PrivateRoute><TransactionAdvisorCompanyPage /></PrivateRoute>} />
        {/* Acquirer */}
        <Route path="/pe/acquirer/exists" element={<PrivateRoute><AcquirerExists /></PrivateRoute>} />

        {/* detailpage */}
        <Route path="/company/:companyId" element={<PrivateRoute><DetailPage /></PrivateRoute>} />
        <Route path="/company-page-pop" element={<PrivateRoute><Popup /></PrivateRoute>} />
        <Route path="/company/:companyId/:dealId" element={<PrivateRoute><DealPage /></PrivateRoute>} />

        {/* angel,incubation,ma deal pages */}
        <Route path="/angel-dealpage" element={<PrivateRoute><AngelDeal /></PrivateRoute>} />
        <Route path="/incubation-dealpage" element={<PrivateRoute><IncubationDeal /></PrivateRoute>} />
        {/* <Route path="/ma-dealpage" element={<PrivateRoute><M_ADealPage /></PrivateRoute>} /> */}


        {/* re detailpage */}
        <Route path="/re-company-page/:companyId" element={<PrivateRoute><REDetailPage /></PrivateRoute>} />
        <Route path="/re-deal-page" element={<PrivateRoute><REDealPage /></PrivateRoute>} />

        {/* ma company detail page */}
        <Route path="/ma-company-page/:companyId" element={<PrivateRoute><MADetailPage /></PrivateRoute>} />
        <Route path="/ma-dealpage" element={<PrivateRoute><M_ADealPage /></PrivateRoute>} />
        {/* ma acquirers */}
        <Route path="/ma-acquirers" element={<PrivateRoute><Acquirer /></PrivateRoute>} />
        <Route path="/ma-acquirers/:acquirersId" element={<PrivateRoute><AcquirerDetail /></PrivateRoute>} />
        {/* ma legal advisors */}
        <Route path="/ma-advisors" element={<PrivateRoute><MaAdvisors /></PrivateRoute>} />
        <Route path="/ma-advisors/:advisorsId" element={<PrivateRoute><MaAdvisorsDetail /></PrivateRoute>} />
        {/* ma Transaction advisors */}
        <Route path="/ma-transaction" element={<PrivateRoute><MaTransaction /></PrivateRoute>} />
        <Route path="/ma-transaction/:advisorsId" element={<PrivateRoute><MaTransactionDetail /></PrivateRoute>} />

        {/* compare company page */}
        <Route path="/compare-companies/:companyId" element={<PrivateRoute><CompareCompany /></PrivateRoute>} />
        <Route path="/compare-company" element={<PrivateRoute><CompareCompanyPopup /></PrivateRoute>} />
        {/* FAQ */}
        <Route path="/pe-faq" element={<PrivateRoute><PeFAQ /></PrivateRoute>} />
        <Route path="/re-faq" element={<PrivateRoute><ReFAQ /></PrivateRoute>} />
        <Route path="/ma-faq" element={<PrivateRoute><MaFAQ /></PrivateRoute>} />
        <Route path="/cfs-faq" element={<PrivateRoute><CfsFAQ /></PrivateRoute>} />
        {/* Header component */}
        {/* Financial detail page */}
        <Route path="/financial/balance-sheet" element={<PrivateRoute><BalanceSheet /></PrivateRoute>} />
        <Route path="/financial/cash-flow" element={<PrivateRoute><CashFlow /></PrivateRoute>} />
        <Route path="/financial/ratios" element={<PrivateRoute><Ratios /></PrivateRoute>} />
        <Route path="/financial/mca-site" element={<PrivateRoute><McaSite /></PrivateRoute>} />
        {/* Setting*/}
        <Route path="/setting" element={<PrivateRoute><AccountSetting /></PrivateRoute>} />
        {/* Notes */}
        <Route path="/notes" element={<PrivateRoute><Notes /></PrivateRoute>} />
        {/* Header */}
        <Route path="/others" element={<PrivateRoute><Others /></PrivateRoute>} />
        {/*Requests*/}
        <Route path="/requests" element={<PrivateRoute><Requests /></PrivateRoute>} />

      </Routes>
    </Router>
    // </Fragment>

  );
}

export default App;
