import { gobalSearchKey } from "../Config/config";

export const TsSearchSchema = [
  
  {
    collection: "pe_company",
    query_by:
      "name,cin,tags,c_tag,sector,website,email,additional_info,board_info__name,management_info__name,is_ma_co,is_pe_co,cin__business_description",
    query_by_weights: "5,4,3,2,1,1,1,1,1,1,1,1,4"
  },

  {
    collection: "re_company",
    query_by:
    "name,cin,sector,website,email,additional_info,board_info__name,management_info__name",
    query_by_weights: "5,4,2,1,1,1,1,1",
  },
  
  {
    collection: "pe_investment",
    query_by:
    "company__name,investors__name,more_info,company_id,advisors_for_company__name,advisors_for_investor__name,date,company__cin,company__tags,company__c_tag,company__sector,company__website,company__email,company__additional_info,company__board_info__name,company__management_info__name,company__cin__business_description",
    query_by_weights: "3,2,1,1,1,1,1,4,3,2,1,1,1,1,1,1,4"
  },
  {
    collection: "pe_exit",
    query_by:
    "company__name,more_info,acquirer__name,exit_investors__name,investment_info,company_id,advisors_for_exit_company__name,advisors_for_buyer__name,advisors_for_seller__name,date,company__cin,company__tags,company__c_tag,company__sector,company__website,company__email,company__additional_info,company__board_info__name,company__management_info__name,company__cin__business_description",
    query_by_weights: "3,1,2,2,3,1,1,1,1,1,4,3,2,1,1,1,1,1,1,4"
  },
  
  {
    collection: "pe_ipo",
    query_by:
      "company__name,more_info,investment_details,selling_investors,ipo_investors__name,company_id,date,company__cin,company__tags,company__c_tag,company__sector,company__website,company__email,company__additional_info,company__board_info__name,company__management_info__name,company__cin__business_description",
    query_by_weights: "3,2,1,3,3,1,1,4,3,2,1,1,1,1,1,1,4"
  },

  {
    collection: "angel",
    query_by:
    "company__name,more_info,investors__name,company_id,date,company__cin,company__tags,company__c_tag,company__sector,company__website,company__email,company__additional_info,company__board_info__name,company__management_info__name,company__cin__business_description",
    query_by_weights: "3,2,1,1,1,4,3,2,1,1,1,1,1,1,4"
  },

  {
    collection: "incubation",
    query_by:
      "company__name,more_info,incubator__name,company_id,date,company__cin,company__tags,company__c_tag,company__sector,company__website,company__email,company__additional_info,company__board_info__name,company__management_info__name,company__cin__business_description",
    query_by_weights: "3,2,1,1,1,4,3,2,1,1,1,1,1,1,4"
  },

  {
    collection: "merger_acquisition",
    query_by:
      "company__name,acquirer__name,more_info,company_group,cin,old_cin,advisors_for_ma_company__name,advisors_for_ma_acquirer__name,company_id,date,company__cin,company__tags,company__c_tag,company__sector,company__website,company__email,company__additional_info,company__board_info__name,company__management_info__name,company__cin__business_description",
    query_by_weights: "3,3,2,1,1,1,2,2,1,1,4,3,2,1,1,1,1,1,1,4"
  },


  {
    collection: "re_investment",
    query_by:
      "company__name,more_info,project_name,investors__name,company_id,date,company__sector,company__additional_info,company__board_info__name,company__management_info__name",
    query_by_weights: "3,1,2,2,1,1,1,1,1,1",
  },
  
  {
    collection: "re_exit",
    query_by:
      "company__name,more_info,acquirer__name,project_name,investment_details,company_id,investors__name,date,company__sector,company__additional_info,company__board_info__name,company__management_info__name",
    query_by_weights: "3,1,2,2,1,1,1,1,1,1,1,1",
  },

  {
    collection: "re_ipo",
    query_by:
      "company__name,more_info,investment_details,ipo_investors__name,date,company__sector,company__additional_info,company__board_info__name,company__management_info__name",
    query_by_weights: "3,2,1,1,1,1,1,1,1",
  },
  
  {
    collection: "cfs_company",
    query_by:
      "full_name,short_name,cin,old_cin,previous_name,subsector,business_description,website,contact_name,auditor_name,tags",
    query_by_weights: "10,5,9,8,5,3,2,1,1,1,5",
  },
  {
    collection: "pe_investor",
    query_by:
      "name,website,email,description,limited_partners,additional_info,key_contact",
    query_by_weights: "5,1,1,1,2,1,2",
  },

  {
    collection: "pe_fund",
    query_by: "fund__name,investor__name,manager,more_info,investor_id",
    query_by_weights: "3,2,1,1,2",
  },

  {
    collection: "re_investor",
    query_by:
      "name,website,email,description,limited_partners,additional_info,management_info__name",
    query_by_weights: "5,1,1,1,2,1,2",
  },

  {
    collection: "pe_acquirer",
    query_by: "name,sector,email,website,additional_info",
    query_by_weights: "5,1,1,1,2",
  },

  {
    collection: "pe_advisor",
    query_by: "name,sector,email,website,contact_person",
    query_by_weights: "5,1,1,1,2",
  },

  {
    collection: "re_advisor",
    query_by: "name,sector,email,website,contact_person",
    query_by_weights: "5,1,1,1,2",
  },
];

export const performSearch = async (searchParameters) => {
    try {
        const url = "https://v2-api-ts.ventureintelligence.com/multi_search";
        const payload = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Typesense-Api-Key': gobalSearchKey
            },
            body: JSON.stringify({ searches: searchParameters })
        };
        const resp = await fetch(url, payload);
        if (!resp.ok) {return {results: []}}
        const searchResults = await resp.json();
        console.log(searchResults);
        return searchResults;
    } catch (error) {
        console.log(error);
        return {results: []}
    }
};


export const getSearchResultIdsAll = async (searchText, results) => {
    const perPage = 250;
    const queries = [];
    let KeyIds = {};

    // Build queries for all collections in the schema
    TsSearchSchema.forEach((schema) => {
        // Find the corresponding result for the current schema's collection
        const matchingResult = results.find(f => f.request_params.collection_name === schema.collection);

        if (matchingResult) {
            const totalPages = Math.ceil(matchingResult.found / perPage);

            const baseQuery = {
                collection: schema.collection,
                query_by: schema.query_by,
                include_fields: "pk",
                enable_highlight_v1: "false",
                highlight_fields: "none",
                highlight_full_fields: "none",
                per_page: perPage,
            };

            // Initialize the KeyIds array for the collection if it doesn't exist
            if (!KeyIds[schema.collection]) {
                KeyIds[schema.collection] = [];
            }

            // Generate search parameters for all pages
            for (let i = 0; i < totalPages; i++) {
                queries.push({
                    ...baseQuery,
                    q: searchText,
                    page: i + 1,
                });
            }
        }
    });

    // Perform the search for all generated queries
    const respJson = await performSearch(queries);

    respJson.results.forEach((result) => {
        const collectionName = result.request_params.collection_name;

        if (!KeyIds[collectionName]) {
            KeyIds[collectionName] = [];
        }

        result.hits.forEach((hit) => {
            KeyIds[collectionName].push(hit.document.pk);
        });
    });
    return KeyIds
}