import React, { useContext, useEffect, useRef, useState } from 'react';
import { getSearchResultIdsAll, performSearch, TsSearchSchema } from '../Components/useGlobalSearch';
import { useLocation, useNavigate, useParams } from 'react-router';
import { SearchResult } from '../Components//SearchPopup/NewSearchColumn';
import { SearchMenuContext } from '../Contexts/SearchMenuContext';
import { TableDataContext } from '../Contexts/TableDataContext';
import { routeNames } from '../routeSegments';
import useModule from '../hooks/useModule';
import { HeaderMenuContext } from '../Contexts/HeaderMenuContext';
import { PeFilterContext } from '../Contexts/PeFilterContext';


const collectionNameMapHuman = {
  pe_company: 'PE-VC Company',
  re_company: 'RE Company',
  cfs_company: 'Private Company Financials (CFS)',
  pe_investor: 'PE-VC Investors',
  pe_fund: 'PE-VC Fund Raising',
  re_investor: 'PE-RE Investors',
  pe_acquirer: 'Acquirer (M&A)',
  pe_advisor: 'To PE-VC / M&A Transactions',
  re_advisor: 'To  PE-RE Transactions',
  pe_investment: 'PE-VC Investments',
  re_investment: 'PE-RE Investments',
  angel: 'Angel Investments',
  incubation: 'Incubation',
  merger_acquisition: 'M&A Deals',
  pe_exit: 'PE-VC Exits',
  re_exit: 'PE-RE Exits',
  pe_ipo: 'PE-VC Backed IPOs',
  re_ipo: 'PE-RE Backed IPOs',
  re_funds: 'PE-RE Fund Raising'
}

const NewSearchResult = () => {
    const { setGlobalSearchResult } = useContext(TableDataContext);
    const [searchResult, setSearchResult] = useState([]);
    const [selectedResult, setSelectedResult] = useState();
    const [selectedStaticHeaders, setSelectedStaticHeaders] = useState("Company Profiles (Transacted)");
    const [selected, setSelected] = useState("pe_company");
    const location = useLocation();
    const [isloading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const { staticHeaders } = useContext(SearchMenuContext);
    const navigate = useNavigate();
    const { isVC } = useModule();
    const { type, input } = useParams();
    const searchType = parseInt(type);
    const [searchInput, setSearchInput] = useState(input)
    const { setSearchText } = useContext(HeaderMenuContext);
    const { setMonthPickerValue } = useContext(PeFilterContext);

    const viewMoreHandler = async (page, collection_name) => {
      console.log(page, "from child")
      setPage(page)
      const schema = TsSearchSchema.filter((schema) => schema.collection === collection_name)
        .map((queryConf) => ({ q: searchInput, page: page, ...queryConf }));

      const response = await performSearch(schema);
      
      const updatedResults = searchResult.reduce((acc, cur) => {
        if(cur.request_params.collection_name === response.results[0]?.request_params.collection_name){
          cur.hits.push(...response.results[0].hits)
          cur.page = response.results[0].page
        }
        acc.push(cur);
        return acc
      }, [])

      setSearchResult(updatedResults);
  };



  const fetchResults = async (searchInput) => {
    if (searchInput.length < 3) { return null }

    const searchParameters = TsSearchSchema.map((queryConf) => ({
      q: searchType === 1 ? `"${searchInput}"` : searchInput,
      page: 1,
      per_page: 5,
      snippet_threshold: 20,
      num_typos:  searchType === 0 ? 2 : 0,
      typo_tokens_threshold: searchType === 0 ? 10 : 0,
      split_join_tokens: searchType === 0 ? "fallback" : "off",
      drop_tokens_threshold: searchType === 0 ? 1 : 0,
      ...queryConf,
    }));


    const searchParameters1 = TsSearchSchema.map((queryConf) => ({
      q: searchInput,
      page: 1,
      per_page: 10,
      num_typos:  2,
      typo_tokens_threshold: 10,
      ...queryConf,
    }));

    try {
        console.log(searchType, "type")
      const results = await performSearch(searchParameters);

      // Check if all found values are 0
      const allResultsEmpty = results.results.every(result => result.found === 0);

      if (allResultsEmpty) {
        setSearchResult([])
        console.log("No results found for the query.");
        setIsLoading(false);
        return;
      } else {
          setIsLoading(false)
          setSearchResult(results.results);
          setSelectedResult(results.results?.filter(result => result?.request_params?.collection_name === "pe_investment"));
      } 
    } catch (error) {
      console.error("An error occurred while fetching results:", error);
    }
  };

  useEffect(() => { fetchResults(searchInput) }, [searchInput, searchType]);

    const onOptionClick = () => {
      setSearchResult([]);
      setPage(1);
    }

    useEffect(() => {
      onOptionClick();
    }, [location.pathname]);

    const columnsLinks = [
      {accessor: "pe_company", link: (!isVC) ? routeNames.pe_companies_pe_vc_investments  : routeNames.vc_companies_vc_investments},
      {accessor:"re_company", link: routeNames.re_companies_re_investments},
      {accessor:"cfs_company", link: routeNames.cfs},

      {accessor: "pe_investor", link: (!isVC) ? routeNames.pe_investors_pe_vc_investments : routeNames.vc_investors_vc_investments},
      {accessor: "pe_fund", link: routeNames.pe_investors_pe_vc_funds},
      {accessor:"re_investor", link: routeNames.re_investors_re_investments},
      {accessor:"pe_advisor", link: routeNames.pe_advisors_pe_vc_advisors},
      {accessor:"re_advisor", link: routeNames.re_advisors_re_investments},

      {accessor:"pe_acquirer", link: routeNames.ma_acquires},
      {accessor: "pe_investment", link: (!isVC) ? routeNames.pe_companies_pe_vc_investments : routeNames.vc_companies_vc_investments},
      {accessor: "pe_exit", link: (!isVC) ? routeNames.pe_companies_exists_pe_vc_investments : routeNames.vc_companies_exists_vc_investments},
      {accessor: "pe_ipo", link: (!isVC) ? routeNames.pe_companies_backed_ipos_pe_vc_investments : routeNames.vc_companies_backed_ipos_vc_investments},
      {accessor: "angel", link: (!isVC) ? routeNames.pe_companies_angel_investments : routeNames.vc_companies_angel_investments},
      {accessor: "incubation", link: (!isVC) ? routeNames.pe_companies_incubation : routeNames.vc_companies_incubation},
      {accessor:"merger_acquisition", link: routeNames.ma_companies},
      {accessor:"re_investments", link: routeNames.re_companies_re_investments},
      {accessor:"re_exit", link: routeNames.re_companies_re_exits},
      {accessor:"re_ipo", link: routeNames.re_companies_re_ipo},
      
    ];
    
    const foundMap = searchResult.reduce((acc, result) => {
      if (result.request_params) {
        const accessor = result.request_params.collection_name;
        acc[accessor] = result.found;
      }
      return acc;
    }, {});

    const RenderLabels = ({ header }) => {

      // Filter the header array to include only items with found > 0
      const filteredHeader = header?.filter(h => foundMap[h.accessor] > 0);

      return (
        // <div className='ml-8 my-4 py-2 w-full bg-[#ebebeb] rounded-lg'>
          <div className='w-full overflow-x-auto px-2 whitespace-nowrap rounded flex items-center'>
            {filteredHeader?.map((h, i) => (
              <span
                className={`mx-2 py-1 px-2 bg-white ${h.accessor === selected ? "text-black font-medium border-black" : " text-gray-700 border-gray-50 hover:border-gray-200"} text-center rounded-md cursor-pointer border  flex items-center justify-center gap-0.5`}
                key={i}
              >
                <button className='min-w-[4rem] text-sm' type="button" onClick={() => {
                  document.getElementById(h.accessor).scrollIntoView({behavior: 'smooth'});
                  setSelected(h.accessor)}}>
                  {h.label}
                  
                </button>
                <div className="text-xs ml-1">
                  ({addCommasToNumber(foundMap[h.accessor])})
                </div>
              </span>
            ))}
          </div>
        // </div>
      );
    };

    const viewAllHandler = async (collection, labelState) => {

      const searchResultsId = await getSearchResultIdsAll(searchInput, searchResult);
      console.log(searchResultsId, "==.");

      setGlobalSearchResult(searchResultsId);
      const matchedLink = columnsLinks.find(link => link.accessor === collection);

      if (matchedLink) {
        navigate(matchedLink.link, { state: labelState });
        setSearchText("");
        setMonthPickerValue(labelState.globalMonthRange)
      } else {
        console.warn('No matching link found for the provided collection:', collection);
      }
    };

    const addCommasToNumber = (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
  
    return (
      <>
        <div className="ml-[75px] fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity h-screen">
          <div className="w-full mt-[3rem] overflow-hidden">
            <div className="w-full h-full max-h-[98vh] border bg-white relative flex flex-col">
              {!isloading ? (
                <div className='ml-8 md:pb-2 sticky'>
                  <div className='mt-2 flex flex-row items-center gap-6'>
                    {Object.keys(staticHeaders).map((headerKey, i) => {
                      const totalFound = staticHeaders[headerKey].reduce((sum, h) => sum + (foundMap[h.accessor] || 0), 0);
                        if (totalFound === 0) {
                          return null; // Skip rendering the staticHeader if the sum is 0
                        }

                      return (
                        <div key={i} className={`py-1 px-5 text-gray-700 ${headerKey === selectedStaticHeaders ?  " bg-[#ebebeb] font-semibold cursor-default rounded-t-sm" : "bg-white rounded-lg cursor-pointer"}`} 
                          onClick={() => {
                            setSelectedStaticHeaders(headerKey);
                            setSelected(staticHeaders[headerKey][0].accessor)
                            document.getElementById(staticHeaders[headerKey][0].accessor).scrollIntoView({behavior: "smooth"})
                          }}>
                            {headerKey}
                            <span className="text-xs ml-1">({addCommasToNumber(totalFound)})</span>
                          </div>
                        )})}
                  </div>
                  <div className="flex flex-row-reverse items-start p-2 border-b bg-[#ebebeb]">
                        <RenderLabels header={staticHeaders[selectedStaticHeaders]} />
                  </div>
                </div>
              ) : null}
              <div className="flex-1 overflow-y-auto overflow-x-auto p-2 scrollbar-remove">
                {isloading ? (
                  <h1 className="mx-auto font-semibold text-center">Loading...</h1>
                ) : (!isloading && searchResult.length === 0) ? (
                  <div className="h-40 flex flex-col justify-center items-center">
                    <h1 className="mx-auto font-semibold text-center">No Results Found</h1>
                    {searchType !== 0 && (
                      <h2 className="mx-auto font-semibold text-center">
                        You may change the search to <span className="font-semibold">broad match </span> and check again.
                      </h2>
                    )}
                  </div>
                ) : (
                  <div className="mx-auto w-full gap-y-6 sm:gap-6 lg:pl-4 lg:pr-4 pl-2 md:pb-2 bg-white max-h-full">
                    {searchResult?.map((result, index) => (
                      result.found !== 0 ? (
                        <div key={index} className='md:m-2 border rounded py-2' id={result?.request_params?.collection_name}>
                          <h1 className="md:text-lg font-semibold md:pl-4 mb-4">{collectionNameMapHuman[result.request_params.collection_name]}</h1>
                          <SearchResult result={result} searchResult={searchResult} />
                          <div className='flex gap-2 justify-center items-center'>
                            {result?.found >= 5 && result?.found !== result?.hits?.length && result?.hits.length !== 0 && (
                              <h3 className="ml-8 mt-2 text-right cursor-pointer text-blue-500 text-blue-600 hover:bg-blue-100 rounded px-4 py-2"
                                onClick={() => viewMoreHandler(page + 1, result?.request_params?.collection_name)}>
                                View more
                              </h3>
                            )}
                            <h3 className="ml-8 mt-2 text-right cursor-pointer text-blue-500 text-blue-600 hover:bg-blue-100 rounded px-4 py-2"
                              onClick={() => {viewAllHandler(result?.request_params?.collection_name, {
                                globalSearch: [{ id: 0, name: `searched for ${searchInput}`, searchTerm: searchInput }],
                                globalMonthRange: ["1998-01-01", "2024-08-31"
                                ]});
                              }}
                              >
                              View all
                            </h3>
                          </div>
                        </div>
                      ) : null
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
    </>
    
    )
}

export default NewSearchResult;