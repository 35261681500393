import React, { useContext } from "react";
import Layout from '../../Components/VC/VcCompanyLayout'
import MainMenu from '../../Components/VC/Mainmenu'
import Card from '../../Components/ListPageCard/ExitCard'
import Table from '../../Components/TableComponent'
// import {PE_TABLE_QUERY} from '../../Queries/Pe';

import RefineSearch from '../../Components/RefineSearchComponent'
import FilterTag from '../../Components/Tag'
// import chatPopupIcon from '../../images/chatPopupIcon.png'
// import ChatPopup from '../../Components/ChatPopup'
import Constants from '../../Constants'
import ExitRefineSearch from '../../Components/RefineSearch/ExitRefineSearch'
import { useLocation } from 'react-router-dom'
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { Switch, Tooltip } from "antd";
import { UserContext } from "../../Contexts/UserContext";
import ToggleDealsViewButton from "../../Components/ToggleDealsView";

/**
 * The `Exists_VC` function is a React component that renders a layout with a top navigation bar, a
 * main area with a primary column and a secondary column, and a bottom section with a table and a
 * refine search sidebar.
 * @returns The function `Exists_VC` returns JSX elements wrapped in a `Layout` component. The returned
 * JSX represents a page layout with a top navigation bar, a main content area divided into two columns
 * (primary and secondary), and a bottom section. The primary column contains a `FilterTag` component,
 * a `MainMenu` component, and a `Table` component. The secondary column (hidden on smaller screens
 */
function Exists_VC() {
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();
    const { pathname } = useLocation(); 
 
    const { refineSearchFilters, dealsView, setDealsView, switchValue, setSwitchValue   } = useContext(PeFilterContext);
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const { isDirectoryUser } = useContext(UserContext);

    const dealsViewHandler = (checked) =>{
        setSwitchValue(checked);
        if(checked){ setDealsView(true) }else setDealsView(false);
    }
   
    return (
        <Layout>
        <div className="flex h-screen flex-col">
                    {/* Top nav*/}
                    {     <div className="hidden lg:block"><Card /> </div> }
                    {/* Bottom section */}
                    <div className="flex min-h-0 flex-1">
                        {/* Narrow sidebar*/}
                        {/* Main area */}
                        <main className= {isFromGlobalSearch() ? " min-w-0 flex-1 border-t border-gray-200 lg:flex":"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>
                            {/* Primary column */}
                            <section
                                className="flex h-full min-w-0 flex-1 overflow-y-auto flex-col lg:order-last">
                                <div className="mr-[4.25rem] flex justify-between items-center">
                                <FilterTag />
                                <ToggleDealsViewButton />
                                </div>
                                {/* Your content */}
                                <MainMenu active={2} menu="exit-vc" primaryTypeMasterId={Constants.primaryMasterIds.vcId}
                                subTypeMasterId={Constants.primaryMasterSubIds.vc_exits_id}
                                typeId={Constants.moduleIds.companiesId}
                                exportFileName={Constants.exportNames.vc_company_exit}/>
                                <div className='overflow-y-auto'>
                                    <Table menu="vc_exit" sub_type_master_id={Constants.subCategoryIds.vc_exits_id}/>
                                </div>
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                                <div className="relative flex flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch>
                                        <ExitRefineSearch typeId={Constants.primaryMasterSubIds.vc_exits_id} customPathname={location.pathname}/>
                                    </RefineSearch>
                                </div>
                            </aside>
                        </main>
                    </div>
                    
                </div>
        </Layout>
    )
}

export default Exists_VC