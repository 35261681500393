import {React, useState, createContext} from 'react'

export const SearchMenuContext = createContext('');

/**
 * The SearchMenuProvider component is a context provider that allows its children components to access
 * and use the search menu data.
 * @returns The SearchMenuProvider component is being returned.
 */
const SearchMenuProvider = ({children}) => {
    const [SearchMenu, setSearchMenu] = useState({
        companyPlaceholder :"Search for companies",
        investorPlaceholder :"Search for investors",
        ladvisorPlaceholder :"Search for legal advisors",
        tadvisorPlaceholder :"Search for transaction advisors",
        cfscompanyPlaceholder:"Search for companies",
        acquirerPlaceHolder :"Search for acquirers",
        data:[]
    }
    );

    const [header, setHeader] = useState([
        {accessor: "pe_company", label: "PE Company"},
        {accessor:"re_company", label:"RE Company"},
        {accessor:"cfs_company", label:"Financials"},

        {accessor: "pe_investor", label: "PE Investor"},
        {accessor:"re_investor", label:"RE Investor"},
        {accessor: "pe_fund", label:"PE Funds"},
        {accessor:"pe_acquirer", label:"PE Acquirer"},
        {accessor:"pe_advisor", label:"PE Advisor"},
        {accessor:"re_advisor", label:"RE Advisor"},

        {accessor: "pe_investment", label: "PE Investments"},
        {accessor: "pe_exit", label: "PE Exits"},
        {accessor: "pe_ipo", label: "PE Backed IPO"},
        {accessor: "angel", label: "Angel"},
        {accessor: "incubation", label: "Incubation"},
        {accessor:"merger_acquisition", label:"M&A"},
        {accessor:"re_investments", label:"RE Investments"},
        {accessor:"re_exit", label:"RE Exit"},
        {accessor:"re_ipo", label:"RE IPO"},
        
    ])

    const staticHeaders = {
      "Company Profiles (Transacted)" : [
        {accessor: "pe_company", label: "PE-VC Company"},
        {accessor:"re_company", label:"RE Company"},
        {accessor:"cfs_company", label:"Financials"}
    ],
      "Deals" : [
        {accessor: "pe_investment", label: "PE-VC Investments"},
        {accessor: "pe_exit", label: "PE-VC Exits"},
        {accessor: "pe_ipo", label: "PE-VC Backed IPOs"},
        {accessor: "angel", label: "Angel Investments"},
        {accessor: "incubation", label: "Incubation"},
        {accessor:"merger_acquisition", label:"M&A Deals"},
        {accessor:"re_investments", label:"PE-RE Investments"},
        {accessor:"re_exit", label:"PE-RE Exits"},
        {accessor:"re_ipo", label:"PE-RE Backed IPOs"},
    ],
      "Company Financials" : [{accessor:"cfs_company", label:"Private Company Financials (CFS)"}],
      "Investors / Acquirers" : [
        {accessor: "pe_investor", label: "PE-VC Investors"},
        {accessor:"re_investor", label:"PE-RE Investors"},
        {accessor: "pe_fund", label:"PE-VC Fund Raising"},
        {accessor: "re_fund", label:"PE-RE Fund Raising"},
        {accessor:"pe_acquirer", label:"Acquirer (M&A)"}
    ],
      "Advisors" : [
        {accessor:"pe_advisor", label:"To PE-VC / M&A Transactions"},
        {accessor:"re_advisor", label:"To  PE-RE Transactions"}
    ]};

    const searchParameters = {SearchMenu, header, staticHeaders};

    return (
        <SearchMenuContext.Provider value={searchParameters}>
            {children}
        </SearchMenuContext.Provider>
    )
}

export default SearchMenuProvider
