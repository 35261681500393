import { useState, useMemo, useCallback, useEffect, useContext, useRef } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import moment from "moment";
import axios from "axios";
import { sortRows, filterRows, paginateRows } from '../../helpers'
import Pagination from '../../Pagination'
import { rowsPerPageData, getFunds ,getPEGlobalSearchCompaniesApi} from '../../../Config/config'
import CompanyLogo from '../../CompanyLogo';
import RandomLogo from '../../RandomLogo';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import addNoted from "../../../images/AtomFavourite_1/png/AtomFavourite.png";
import addNotedActive from '../../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import AddNote from "../../../Pages/addNote";
import '../../TableView.css'
import { PeFilterContext } from '../../../Contexts/PeFilterContext'
import { TableDataContext } from '../../../Contexts/TableDataContext'
import { TableHeaderContext } from '../../../Contexts/TableHeaderContext';
import { routeNames } from '../../../routeSegments';
import NoDataPlaceholder from '../../shared/NoDataPlaceholder';
import Loader from '../../Loader';
import OverflowToolTip from '../../shared/OverflowToolTip';
import AddNotePopover from '../../shared/AddNotePopover';
import Constants from '../../../Constants';
import useModule from "../../../hooks/useModule";
import DeleteItemsFromGroup from '../../shared/DeleteItemsFromGroup';


const Table = ({ columns, menu,sub_type_master_id }) => {

  const { monthPickerValue, setIsMonthPickerChanged, setIsTagClosed, refineSearchFilters,groupId } = useContext(PeFilterContext);
  const { rows, setRows, data, setData, isLoad, setIsLoad, isDataLoad, setIsDataLoad, currentPage, setCurrentPage,allId,setAllId, globalSearchResult } = useContext(TableDataContext);


  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: 'desc', orderBy: 'fundingDate' })
  const [showId, setShowId] = useState(null);
  const [showNote, setShowNote] = useState(false)
  const location = useLocation(); 
  const abortRef = useRef(null)
  const[reload, setReload] = useState(false)
/**
 * The function `findActivePage` sets the current page, triggers a table render, and updates some state
 * variables.
 * @param page - The "page" parameter is the current page number that is being passed to the
 * "findActivePage" function.
 */
  const findActivePage = (page) => {
    setCurrentPage(page)
    setIsLoad(true)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order,groupId)
    // setIsDataLoad(false)
    setShowNote(false)
    setShowId(null)
  }

  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage);
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order,groupId)
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    return () => {
      abortController.abort();
    }
  }, [monthPickerValue, refineSearchFilters,groupId,reload]);
  
/**
 * The function `isFromGlobalSearch` checks if there are any global search filters applied based on the
 * current location pathname.
 */
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false


  /* The above code is a JavaScript function that is used to render a table. It makes an HTTP POST
  request using the axios library to fetch data from an API endpoint. The request payload includes
  various parameters such as page, month, column, order, and groupId. The response data is then used
  to update the state variables and populate the table rows. */
  const tableRender = useCallback((page, month, column, order,groupId) => {
    setIsTagClosed(0)
    setIsMonthPickerChanged(0)

    axios(`${getFunds}`, {
      method: "POST",
      signal: abortRef.current.signal,
      data:{
        "date_range": isFromGlobalSearch() ? {
          "from_date": "1998-01-01",
          "to_date": "2024-08-31"
        }
      : {
          "from_date": moment(month[0]).format("YYYY-MM-DD"),
          "to_date": moment(month[1]).format("YYYY-MM-DD"),
        },
        "order_by": {
          "column": column,
          "direction": order
        },
        "page": page,
        FundIds: isFromGlobalSearch() ? globalSearchResult.pe_fund : refineSearchFilters[location.pathname]?.globalSearch?.at(0)?.value,
        "stage_type": refineSearchFilters[location.pathname]?.fundStage?.map(option => option.id),
        "industry_type": refineSearchFilters[location.pathname]?.fundIndustry?.map(option => option.id),
        "capital_source": refineSearchFilters[location.pathname]?.capitalSource?.map(option => option.id),
        "raising_status": refineSearchFilters[location.pathname]?.fundStatus?.map(option => option.id),
        "close_status": refineSearchFilters[location.pathname]?.closeStatus?.map(option => option.id),
        "size": (refineSearchFilters[location.pathname]?.fundSize?.length ?? 0) != 0 ? {
          "start": refineSearchFilters[location.pathname]?.fundSize?.at(0)?.name,
          "end": refineSearchFilters[location.pathname]?.fundSize?.at(1)?.name,
        } : null,
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
    }
      },
      headers: {

        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response?.data?.message == "The funds list has been listed successfully") {
          console.log(response?.data, "axios api response")
          setIsLoad(false)
          setIsDataLoad(false)
          setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.fundList)
          setAllId(response?.data?.all_fund_ids)
          // setCompaniesId([])
          // setCompaniesId(response?.data?.all_companies_ids)
          setData(response?.data)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  }, [refineSearchFilters]);
  console.log(rows, "fundrows")

 /**
  * The addNote function toggles the visibility of a note and sets the id of the note to be shown.
  * @param id - The `id` parameter is the identifier of a note.
  */
  const addNote = (id) => {
    setShowNote(!showNote)
    setShowId(id)
  };


/* The above code is using the `useMemo` hook in React to memoize the result of calling the
`filterRows` function with the `rows` and `filters` variables as arguments. This means that the
`filterRows` function will only be called again if either `rows` or `filters` change. The memoized
result is stored in the `filteredRows` constant. */
  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  console.log(filteredRows, "filteredrowspe");
  // const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  // const calculatedRows = sortedRows
  // console.log(calculatedRows, "pagination123")
  const count = filteredRows.length
  console.log(count, "countpe");

 /**
  * The function `handleSort` is used to handle sorting of data in a table based on a given accessor
  * and order.
  * @param accessor - The `accessor` parameter is a value that represents the property or key of the
  * object that you want to sort by. It is used to identify the specific property or key that you want
  * to sort the data by.
  * @param order - The "order" parameter is used to determine the sorting order of the data. It can
  * have two possible values: 'asc' for ascending order and 'desc' for descending order.
  */
  const handleSort = (accessor, order) => {
    setIsLoad(true);
    setRows([])

    setCurrentPage(currentPage)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, monthPickerValue, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc',groupId)
  }


  /**
   * The function `addCommasToNumber` takes a number as input and returns a string representation of
   * the number with commas added for thousands separators.
   * @param number - The `number` parameter is the number to which commas need to be added.
   * @returns The function `addCommasToNumber` returns a string representation of the input number with
   * commas added as thousands separators.
   */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  useEffect(()=>{
    console.log(location?.state?.isFromGlobalSearch, 'isFromGlobalSearch');

  },[location?.state?.isFromGlobalSearch])



  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className="overflow-x-auto scrollbar  rounded-b-[6px]">
              <table className="listPage table w-full p-1 bg-grey-50 divide-y" width="50%">
                <thead className="text-[11pt] leading-[16pt] bg-[#EBEBEB] lg:text-[13px]  font-semibold lg:leading-[15px]  text-[#666666] text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor == sort.orderBy) {
                          if (sort.order == 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (

                        <th className={ i==0 || i==1 ?"text-left bg-[#EBEBEB] lg:relative  sticky left-0 md:pl-5 md:pr-5 pl-5 pr-[9rem] lg:pr-[1rem] ": i==3 ? "px-8 lg:px-5": "px-5 " +(" py-2 font-sans_book_body")} key={i}>
                          <span className='flex'><OverflowToolTip component={<a className=' cursor-text hover:text-[#000000]'>{column.accessor == "add" && groupId?.myList?.length > 0 ? "Delete": column.label ? column.label : "--"}</a>} />
                            {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                  <tbody className="text-[11pt] leading-[16pt]  lg:text-[14px] text-[#333333] lg:leading-[16px] font-sans_book_body text-left">
                  {rows.length > 0 ?
                        rows?.map((row, i) => {
                          // console.log(menu,"funds menu")
                          return (

                            <tr key={i} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                              <td width="25%" className="text-left sticky left-0 lg:relative  text-[#333333]  bg-white  px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center card-animate" style={{whiteSpace: "initial"}} >
                                {row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                                  <RandomLogo name={row?.firm_name} />
                                  :
                                  <CompanyLogo name={row?.firm_name} id={row?.firm_id} page="company page" logo={row?.logo_path} />
                                }
                                <OverflowToolTip  className="w-[10rem] lg:w-auto " component={
                                  <NavLink className="hover:text-[#BC8B20] text-start lg:inline flex text-[#333333]" state={{ prevPath: location.pathname }} to={menu == "inv_l_partner_vc" ?
                                    routeNames.vc_investors_funds_detail_page(row.firm_id, row.deal_id) : routeNames.pe_investors_funds_detail_page(row.firm_id, row.deal_id)}>
                                    {row.firm_name ? row.firm_name : "--"}
                                  </NavLink>} />
                                {/* <NavLink className="hover:text-[#BC8B20] text-[#333333]" state={{ prevPath: location.pathname }} to={
                            routeNames.pe_investors_funds_detail_page(row.firm_id, row.deal_id) }>
                              {row.firm_name?row.firm_name:"--"}
                            </NavLink> } /> */}
                              </td>
                              <td width="15%" className="px-5 py-3 font-sans_book_body" style={{whiteSpace: "initial"}}>
                                <OverflowToolTip className="w-[10rem] lg:w-auto" component={
                                  <NavLink className="hover:text-[#BC8B20] text-[#333333]" state={{ prevPath: location.pathname }} to={menu == "inv_l_partner_vc" ?
                                    routeNames.vc_investors_funds_detail_page(row.firm_id, row.deal_id) : routeNames.pe_investors_funds_detail_page(row.firm_id, row.deal_id)}>
                                    {row.fund_name ? row.fund_name : "--"}
                                  </NavLink>}
                                /></td>
                              <td className="pl-[1.2rem] py-3 font-sans_book_body text-left" > <a >{row.type ? row.type : "--"}</a></td>
                              <td className="w-[5%] pr-[3rem] md:pr-[3.3rem] lg:pr-11 py-3 font-sans_book_body text-right " > <a >{row.size !=0 ? addCommasToNumber(row.size) : "--"}</a></td>
                              <td className="w-[10%] pr-[2.3rem] md:pr-[3.4rem] lg:pr-[2.9rem] px-5 py-3 font-sans_book_body text-right  " ><a >{row.amount_raised !=0 ? addCommasToNumber(row.amount_raised) : "--"}</a></td>
                              <td className="pl-[1.2rem] py-3 font-sans_book_body text-left" ><a >{row.status ? row.status : "--"}</a></td>
                              <td className="px-5 py-3 font-sans_book_body lg:pr-[1.2rem]" >
                                <OverflowToolTip component={
                                  <a className="hover:text-[#333333] cursor-text">{row.capital_source ? row.capital_source : "--"}</a>
                                }
                                />
                              </td>
                              <td className="w-[5%] pl-4 py-3 font-sans_book_body " >
                                <NavLink className="hover:text-[#BC8B20] text-[#333333]" 
                                  state={{ prevPath: location.pathname }} 
                                  to={menu == "inv_l_partner_vc" ?
                                      routeNames.vc_investors_funds_detail_page(row.firm_id, row.deal_id) : routeNames.pe_investors_funds_detail_page(row.firm_id, row.deal_id)}
                                >
                                { row.date ? row.date : "--"}
                                </NavLink>
                                </td>

                              <td className="w-[5%] px-[10px] py-3 font-sans_book_body " >
                              {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.fundsId} setReload={setReload}/>
                                 :
                                <AddNotePopover className="mx-3" key={row?.fund_id} companyId={row?.fund_id} companyName={row.firm_name} type_id={Constants.moduleIds.fundsId} investor_id={row.firm_id} deal_id={row.deal_id} sub_type_master_id={sub_type_master_id}/>
                              }
                              </td>
                            </tr>
                          )
                        })
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></> : <></>
                }
              </table>
              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='w-full h-[17rem] bg-white flex items-center justify-center '>
                    <NoDataPlaceholder />
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {data?.no_of_funds && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.no_of_funds}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      ) : (
        ""
      )}
      {/* <div>
          <p>
            <button onClick={clearAll}>Clear all</button>
          </p>
        </div> */}
    </>
  )
}

export default Table