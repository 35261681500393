import axios from 'axios';
import { Fragment, React, useState, useEffect, useCallback, useContext } from 'react'
import Constants from '../../Constants';
import ConfirmPopup from '../../Utils/ConfirmPopup';
import pdf from "../../images/pdf.png";
import xls from "../../images/xls/png/Group 47.png"
import { Dropdown, Spin, Tooltip } from 'antd';
import { Dialog, Transition } from "@headlessui/react";
import { UserContext } from '../../Contexts/UserContext';
import { LoadingOutlined } from '@ant-design/icons';
import { newInvestorExportUrl } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableDataContext } from '../../Contexts/TableDataContext';

/**
 * The `NewInvestorDownloader` function is a component that renders a dropdown menu with options
 * for downloading Excel files, and handles the logic for downloading the selected file.
 * @returns a JSX element.
 */
function NewInvestorDownloader({ category, section, type, menuItems }) {
    const [alert, setAlert] = useState({ show: false, message: Constants.excelPopupMessage, key: "", })
    const { getToken, logout } = useContext(UserContext);
    const [reachedLimitPopup, setReachedLimitPopup] = useState({ show: false, message: "" })
    const [isDownloading, setIsDownloading] = useState(false);
    const { monthPickerValue } = useContext(PeFilterContext);
    const { tableInvestorId,currentActiveInvestorId } = useContext(TableDataContext);

    const onChange = async (flag) => {
        if (flag === 1) {
            const result = await downloadExcel(alert.key, alert.fileName)
            if (result === true) {
            } else {
                setReachedLimitPopup({ show: true, message: result })
            }
        }
        setAlert(prev => ({ ...prev, show: false, key: "" }))
    }

    const downloadExcel = async (key, fileName) => {
        setIsDownloading(true);
        const result = await axios.post(newInvestorExportUrl, {
            "type": type,
            "category": category,
            "section": section,
            "download_type": key,
            investor_id: currentActiveInvestorId,
            "date_range": {
                "from_date": monthPickerValue[0],
                "to_date": monthPickerValue[1]
            }
        },
            {
                responseType: 'blob',
                headers: {
                    "Authorization": getToken()
                }
            }).then((response) => {
                setIsDownloading(false);
                if (response.status === 200) {
                    console.log("haederhaederhaeder", response);
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `${fileName}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    return true
                }
            }).catch(async (error) => {
                setIsDownloading(false);
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout()
                } else if (error?.response?.status == 400) {
                    const dataAsText = await error?.response?.data?.text()
                    const parsedData = JSON.parse(dataAsText)
                    return parsedData?.message
                }
            })
            return result
    }

    const onItemSelect = (e) => {
        const selectedItem = menuItems.filter(item => item.key === e.key)?.at(0);
        setAlert(prev => ({ ...prev, show: true, key: e.key, fileName: selectedItem.fileName, }))
    }

    if (reachedLimitPopup.show) {
        return <div className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
            <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mx-4 md:mx-0 sm:w-full sm:max-w-lg'>
                <div className='w-full flex flex-col '>
                    <div className="mt-3 text-center sm:mt-0 px-5 py-3 sm:ml-4 sm:text-left">
                        <h1 className='text-left py-1 text-lg font-bold leading-6 text-gray-900'>Alert!</h1>
                        <div className="mt-4 ">
                            <p className="text-sm text-gray-500 text-start">{reachedLimitPopup.message}</p>
                        </div>
                    </div>
                </div>
                <div className='flex bg-gray-50 px-4 pb-3 flex-row-reverse'>
                    <button onClick={() => setReachedLimitPopup(prev => ({ ...prev, show: false }))} className='inline-flex w-full justify-center font-sans_bold_body rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'>
                        Close
                    </button>
                </div>
            </div>
        </div>
    }

    return (
        <div>
            <ConfirmPopup {...alert} onChange={onChange} icon={<></>} activeBtnName={isDownloading ? "Downloading" : "Download"} closeBtnName='Close' title='Select option for pdf file export' disable={isDownloading}/>
            {isDownloading
                ? <Spin indicator={<LoadingOutlined style={{ fontSize: 26, color: "#BC8B20", }} spin />} />
                : <Dropdown
                    menu={{
                        items: menuItems.map(item => ({ ...item, onClick: onItemSelect }))
                    }}
                >
                    <img src={xls} className="h-[24px] max-w-[21px] cursor-pointer" alt="" />
                </Dropdown>
                // : <Tooltip title="Export">
                //     <span onClick={() => setAlert(prev => ({ ...prev, show: true }))}>
                //         {children || <img src={pdf} className="cursor-pointer  mr-2 h-[24px] w-[21px] " alt="" />}
                //     </span>
                // </Tooltip>
            }
        </div>

    )
}

export default NewInvestorDownloader
