import React, { useState, useEffect, useContext } from "react"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import MonthPickerComponent from '../../Utils/MonthPickerComponent'
import { TableDownloadContext } from '../../Contexts/TableDownloadContext'
import moment from 'moment';
import line from "../../images/line.png"
import xls from "../../images/xls/png/Group 47.png"
import FileIcon from "../../images/file.png"
import FileIconBlur from "../../images/fileblur.png"
import ExcelPopup from "../../Components/ExcelPopup";
import '../MainMenu.css'
import { routeNames } from "../../routeSegments";

import SavedFilterContent from "../../Components/SavedFilter";
import SavedFilterICON from "../../Components/SavedFilter/SavedFilterICON";
import ExportPopup from "../../Components/ExportPopup";
import { data, vcinvestorProfileExportData, vcinvestorbackedIPOExportData, vcinvestorAngelInvestmentExportData, vcinvestorexitExportData, vcinvestorincubationExportData } from "../../Components/ExportPopup/ExportData"
import { exportInvestorExcel } from "../../Config/config";
import { TableDataContext } from "../../Contexts/TableDataContext";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import Constants from "../../Constants";
import { UserContext } from "../../Contexts/UserContext";
import { useLocation } from 'react-router-dom'
import { ExportDirectoryExcel } from "../ExportPopup/DirectoryExport";

function MainMenu(props) {
    // console.log(props.menu, "menuprops");

    const titleMap = {
        "vcinvestor": "VC Investors VC Investment - Venture Intelligence",
        "exits_vc": "VC Investors VC Exits - Venture Intelligence",
        "ma_vc": "VC Investors Exits Via M&A (VC) - Venture Intelligence",
        "public_vc": "VC Investors Exits Via Public Market (VC) - Venture Intelligence",
        "vcbackedipo": "VC Investors VC Backed IPOs - Venture Intelligence",
        "angel": "VC Investors Angel Investments - Venture Intelligence",
        "incubation": "VC Investors Incubation - Venture Intelligence",
    };
    
    document.title = titleMap[props.menu] || "Venture Intelligence";

    // const [save, setSave] = useState(false);
    const { save, setSave,showMonthPicker ,refineSearchFilters,groupId} = useContext(PeFilterContext);

    const {activeTabDeal,setActiveTabDeal,setCurrentPage, globalSearchResult} =useContext(TableDataContext);

    const { handleDownloadExcel } = useContext(TableDownloadContext)
    const [openTab, setOpenTab] = React.useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ]);
      const { isStudent, isDirectoryUser ,isTrialExport} = useContext(UserContext)


    /**
     * The function `savedList` toggles the value of the `save` variable.
     */
    const savedList = () => {
        setSave(!save)
    }


   /* The above code is using the useEffect hook in React to set the value of the "openTab" state
   variable to the value of the "active" prop passed to the component. It is also specifying that
   the effect should only run when the "openTab" state variable changes. */
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])


    /**
     * The function `download` calls the `handleDownloadExcel` function.
     */
    const download = () => {
        handleDownloadExcel()
    }

    const { pathname } = useLocation(); 
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    const category =
      props.menu === "investor"
        ? "pevc_count"
        : props.menu === "pevcExit"
        ? "exit_count"
        : props.menu === "backedipo"
        ? "ipo_count"
        : props.menu === "angel"
        ? "angel_count"
        : props.menu === "incubation"
        ? "incubation_count"
        : ""; 

    console.log(props.menu,  'catee');
    
    return (
        <div className="pl-3 pr-4 lg:pr-4 md:pr-5">

            <div className="scrollbar  rounded">
                <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    <div className="flex flex-row  space-x-5  overflow-x-auto scrollbar-remove">
                        <div className="py-4  px-2 font-[Squeal Sans]">
                            <NavLink className={"whitespace-nowrap text-[12pt] leading-[17px] lg:text-[14px] lg:leading-[17pt]" + (openTab === 1 ? " font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100")
                            }
                                data-toggle="tab"
                                to={props.menu == "vcinvestor" ? routeNames.vc_investors_vc_investments : routeNames.vc_investors_vc_investments}
                                role="tablist"
                            >
                                <div className="group inline-block">
                                    <button onClick={()=>setCurrentPage(1)}
                                        className="outline-none w-[7rem] focus:outline-none py-1 bg-white rounded-sm flex items-center "
                                    >
                                        <span className="  flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            {props.menu == "vc" ? "VC Investments" : "VC Investments"}
                                        </span>
                                        {/* <span>
                                            <svg
                                                className="fill-current h-4 w-4 transform group-hover:-rotate-180 
                                            transition duration-150 ease-in-out"
                                                xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                                <path
                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                />
                                            </svg>
                                        </span> */}
                                    </button>
                                    {/* {
                                            props.menu == "vcinvestor" ?
                                    <ul
                                        className=" border border-gray-300   absolute  mt-1 bg-white rounded-sm transform scale-0 group-hover:scale-100 z-10 absolute 
                                    transition duration-150 ease-in-out origin-top min-w-32"
                                    >
                                         <NavLink to='/investors/pe-vc-investments' className="rounded-sm px-3 py-1 hover:bg-gray-100 text-[#A5711A] text-[13px] leading-[15px] text-[13px] leading-[15px]">PE-VC Investments</NavLink>
                                    </ul>
                                    :
                                    ""
                                     } */}
                                </div>
                            </NavLink>
                        </div>
                        <div className="py-4 pl-2 lg:pl-0">
                            <NavLink className={" whitespace-nowrap text-[12pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] pl-2" + (openTab === 2 ? "text-[#333333] font-bold border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                : " whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100 ")
                            }
                                to={
                                    props.menu == "ma_vc" ? routeNames.vc_investors_exists_ma_vc_investments :
                                        props.menu == "public_vc" ? routeNames.vc_investors_exists_pm_vc_investments :
                                            routeNames.vc_investors_exists_vc_investments}
                                data-toggle="tab"
                                role="tablist"
                            >
                                {isFromGlobalSearch() ?
                                 <div className="group inline-block">
                                 <button onClick={()=>setCurrentPage(1)}
                                     className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center "
                                 >
                                     <span className="  flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                         {
                                             props.menu == "ma_vc" ? "M&A (VC)" :
                                                 props.menu == "public_vc" ? "Public Market (VC)" : "VC Exits"}</span>
                                     
                                 </button>

                                
                             </div>
                              :  <div className="group inline-block">
                                    <button onClick={()=>setCurrentPage(1)}
                                        className="outline-none w-30  justify-between focus:outline-none py-1 bg-white rounded-sm flex items-center "
                                    >
                                        <span className="  flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                            {
                                                props.menu == "ma_vc" ? "M&A (VC)" :
                                                    props.menu == "public_vc" ? "Public Market (VC)" : "VC Exits"}</span>
                                        <span>
                                            <svg
                                                className="fill-current h-4 w-4 transform group-hover:-rotate-180
                                            transition duration-150 ease-in-out"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                                />
                                            </svg>
                                        </span>
                                    </button>

                                    <ul
                        className="mt-2 bg-white rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 transform scale-0 group-hover:scale-100 z-10 absolute 
                                    transition duration-150 ease-in-out origin-top min-w-[9rem] md:min-w-[12.5rem]"
                      >
                                        {
                                            props.menu == "ma_vc" ?

                                            <ul
                                            className=" bg-white  py-1 rounded-lg shadow-lg lg:ring-1 ring-black ring-opacity-5 absolute top-0 md:right-0 right-[26px] transition duration-150 ease-in-out origin-top-left min-w-32"
                                          >
                                                    <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                        <NavLink to={routeNames.vc_investors_exists_vc_investments}
                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                            <span onClick={()=>setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]"> VC Exits
                                                            </span>

                                                        </NavLink>
                                                    </li>

                                                    <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                        <NavLink to={routeNames.vc_investors_exists_pm_vc_investments}
                                                            className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                        >
                                                            <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Public Market (VC)</span>
                                                            <span className="mr-auto">

                                                            </span>
                                                        </NavLink>

                                                    </li>
                                                </ul>

                                                : props.menu == "public_vc" ?

                                                    <ul
                                                        className="bg-white  bg-white py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0 right-[52rem] transition duration-150 ease-in-out origin-top-left min-w-32"
                                                    >
                                                        <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                            <NavLink to={routeNames.vc_investors_exists_vc_investments}
                                                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                <span onClick={()=>setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">VC Exits
                                                                </span>

                                                            </NavLink>
                                                        </li>
                                                        <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                            <NavLink to={routeNames.vc_investors_exists_ma_vc_investments}
                                                                className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                            >
                                                                <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Via M&A (VC)</span>
                                                                <span className="mr-auto">

                                                                </span>
                                                            </NavLink>

                                                        </li>
                                                    </ul>

                                                    :
                                                    props.menu == "exit-ma-pe-vc" ?

                                                        <ul
                                                            className="bg-white  bg-white py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0 right-[52rem]  transition duration-150 ease-in-out origin-top-left min-w-32"
                                                        >
                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                                <NavLink to={routeNames.vc_investors_exists_vc_investments}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none">
                                                                    <span onClick={()=>setCurrentPage(1)} className=" flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">PE-VC Exits
                                                                    </span>

                                                                </NavLink>
                                                            </li>
                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                                <NavLink to={routeNames.vc_investors_exists_ma_vc_investments}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                >
                                                                    <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]"> Via M&A (VC)</span>
                                                                    <span className="mr-auto">

                                                                    </span>
                                                                </NavLink>

                                                            </li>
                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                                <NavLink to={routeNames.pe_investors_exists_pm_pe_vc_investments}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                >
                                                                    <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Via Public Market (VC)</span>
                                                                    <span className="mr-auto">

                                                                    </span>
                                                                </NavLink>

                                                            </li>
                                                        </ul>






                                                        :
                                                        <ul
                                                            className="bg-white  bg-white py-1 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 absolute top-0 lg:right-0 right-[52rem] transition duration-150 ease-in-out origin-top-left min-w-32"
                                                        >
                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                                <NavLink to={routeNames.vc_investors_exists_ma_vc_investments}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                >
                                                                    <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Via M&A (VC)</span>
                                                                    <span className="mr-auto">

                                                                    </span>
                                                                </NavLink>

                                                            </li>
                                                            <li className="rounded-sm font-sans_book_body px-6 py-2 relative  hover:bg-gray-100  ">
                                                                <NavLink to={routeNames.vc_investors_exists_pm_vc_investments}
                                                                    className=" navlink w-full text-left flex items-center outline-none focus:outline-none"
                                                                >
                                                                    <span onClick={()=>setCurrentPage(1)} className="pr-1 flex-1 text-[#333333] lg:text-[13px] lg:leading-[15px] text-[12pt] leading-[15pt]">Via Public Market (VC)</span>
                                                                    <span className="mr-auto">

                                                                    </span>
                                                                </NavLink>

                                                            </li>
                                                        </ul>
                                        }

                                    </ul>
                                </div>}
                            </NavLink>
                        </div>
                        <div className="py-4 ">

                            <NavLink
                                className={
                                    " whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt] " +
                                    (openTab === 3 ?
                                        "font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                        : "  whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                                }
                                to={props.menu == "vcinvestor" ? routeNames.vc_investors_backed_ipos_vc_investments : routeNames.vc_investors_backed_ipos_vc_investments}
                                data-toggle="tab"
                                role="tablist"
                            >
                                <div className="group inline-block">
                                    <button onClick={()=>setCurrentPage(1)}
                                        className="outline-none w-[7rem] focus:outline-none py-1 bg-white rounded-sm flex items-center min-w-[7rem]"
                                    >
                                        <span className="  flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">{props.menu == "vcinvestor" ? "VC Backed IPOs" : "VC Backed IPOs"}</span>

                                    </button>

                                </div>
                            </NavLink>
                        </div>

                        <div className="lg:py-4 py-4 pl-3 lg:pl-2 ">
                            <NavLink
                                className={
                                    " whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt] " +
                                    (openTab === 4 ?
                                        "font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                        : "  whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                                }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(4)
                                // }}
                                data-toggle="tab"
                                to={routeNames.vc_investors_angel_investments}
                                role="tablist"
                            >
                                <span onClick={()=>setCurrentPage(1)}>Angel Investments</span>
                            </NavLink>

                        </div>
                        <div className="lg:py-4 lg:pl-1 pl-2 py-4">
                            <NavLink
                                className={
                                    " whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt] " +
                                    (openTab === 5 ?
                                        "font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                        : "  whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100  ")
                                }
                                // onClick={e=> {
                                //     e.preventDefault();
                                //     setOpenTab(5)
                                // }}
                                data-toggle="tab"
                                to={routeNames.vc_investors_incubation}
                                role="tablist"
                            >
                                <span onClick={()=>setCurrentPage(1)}>Incubation</span>
                            </NavLink>
                        </div>
                    </div>
               <div className={"md:flex justify-between items-center px-3 lg:px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? '':'flex')}>
                {!isFromGlobalSearch() && <label htmlFor="" className="text-[#666666]  ">
                    <div className={showMonthPicker!=0 ?"":'flex'}>
                        <MonthPickerComponent />
                    </div>
                </label>}
                <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>    
                {isTrialExport =='false' || isStudent == 'true' ? <></>  : <>                    
                        <button >
                            <img src={xls} className="h-[24px] mt-1 max-w-[21px]" alt=""
                                onClick={() => setShowPopup(!showPopup)}
                            />
                            {showPopup && (
                                <div>
                                    {isDirectoryUser ? (
                                            <ExportDirectoryExcel
                                              modules="investor"
                                              url={exportInvestorExcel}
                                              fileName={props.exportFileName}
                                              module_type={
                                                props.primaryTypeMasterId ===
                                                Constants.primaryMasterIds.peId
                                                  ? "pe"
                                                  : "vc"
                                              }
                                              exportOptions={props.menu === "vcinvestor" ? vcinvestorProfileExportData : props.menu === "vcbackedipo" ? vcinvestorbackedIPOExportData : props.menu === "angel" ? vcinvestorAngelInvestmentExportData : props.menu === "incubation" ? vcinvestorincubationExportData : vcinvestorexitExportData} type={
                                            props.menu === "vcinvestor" ? "vc_investment" : props.menu === "vcbackedipo" ? "vcipo" : props.menu === "angel" ? "angel" : props.menu === "incubation" ? "incubation" : props.menu === "ma_vc" ? "vc_ma_exit" : props.menu === "public_vc" ? "vc_pm_exit" : "vc_exit"}
                                              onClose={() => setShowPopup(false)}
                                            />
                                          ) : (
                                    <ExportPopup onClose={() => setShowPopup(false)}
                                        modules="investor"
                                        fileName={props.exportFileName}
                                        url={exportInvestorExcel}
                                        module_type={props.primaryTypeMasterId===Constants.primaryMasterIds.vcId?"vc":"pe"}
                                        exportOptions={props.menu === "vcinvestor" ? vcinvestorProfileExportData : props.menu === "vcbackedipo" ? vcinvestorbackedIPOExportData : props.menu === "angel" ? vcinvestorAngelInvestmentExportData : props.menu === "incubation" ? vcinvestorincubationExportData : vcinvestorexitExportData} type={
                                            props.menu === "vcinvestor" ? "vc_investment" : props.menu === "vcbackedipo" ? "vcipo" : props.menu === "angel" ? "angel" : props.menu === "incubation" ? "incubation" : props.menu === "ma_vc" ? "vc_ma_exit" : props.menu === "public_vc" ? "vc_pm_exit" : "vc_exit"}
                                    />
                                )}
                                </div>
                            )}
                        </button>  
                        {props.menu == "incubation" ||   
                        groupId?.myList?.length > 0 ? <></>  :                       
                    
                    <a>
                        <img className="h-[24px] max-w-[2px] mt-1 " src={line} />
                    </a>}
                    </>}
                    {props.menu == "incubation" ||
                    groupId?.myList?.length > 0 ? <></>  :                       
                    <SavedFilterICON
                        save={save}
                        FileIcon={FileIcon}
                        FileIconBlur={FileIconBlur}
                        savedList={savedList} />
                    }
                </div>
            </div>
                </div>
                {save ? <SavedFilterContent primaryTypeMasterId={props.primaryTypeMasterId}
                    subTypeMasterId={props.subTypeMasterId}
                    typeId={props.typeId}
                    show={save} /> : ''}
            </div>
        </div>
    )
}

export default MainMenu